const initialState = {
  allCategories: [],
  selectedCategory: { id: '', name: 'Alle produkter' },
};

export default function brandsReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_ALL_CATEGORIES':
      return {
        ...state,
        allCategories: action.value,
      };
    case 'UPDATE_SELECTED_CATEGORY':
      return {
        ...state,
        selectedCategory: action.value,
      };
    default:
      return state;
  }
}
