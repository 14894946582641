import React from 'react';
import { CircularProgress } from '@mui/material';
import './styles.scss';

function Loading() {
  return (
    <div className="loadingPageContainer">
      <CircularProgress size={50} />
    </div>
  );
}

export default Loading;
