import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { TextField, Button, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchUserProfile } from 'actions/userAction';
import { updateAllClubs, updateSelectedClub } from 'actions/clubsActions';
import { loginWithSmsToken, getOneTimePassword, API } from '../../util/Api';
import './styles.scss';

function Login({ selectedClub }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  const [phoneNumber, setPhoneNumber] = useState('');
  const [savedPhoneNumber, setSavedPhoneNumber] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [codeReceived, setCodeReceived] = useState(false);
  const [loading, setLoading] = useState(false);

  const onPhoneNumberChange = event => {
    setPhoneNumber(event.target.value);
    if (codeReceived) {
      setCodeReceived(false);
    }
    if (savedPhoneNumber === event.target.value && savedPhoneNumber !== '') {
      setCodeReceived(true);
    }
  };

  const onSmsCodeChange = event => {
    setSmsCode(event.target.value);
  };

  const requestSms = async () => {
    try {
      setLoading(true);
      await getOneTimePassword(phoneNumber);
      setSavedPhoneNumber(phoneNumber);
      setCodeReceived(true);
    } catch (err) {
      if (err.response) {
        const { error, message } = err.response.data;
        toast.error(
          message || error[0] || 'Henting av nødvendig informasjon feilet!',
        );
      } else {
        toast.error('Henting av nødvendig informasjon feilet!');
      }
    } finally {
      setLoading(false);
    }
  };

  const checkIfUserStillHasClub = (chosenClub, userClubs) => {
    const clubFound = userClubs.find(club => club.id === chosenClub.id);

    if (clubFound) {
      return true;
    }
    return false;
  };
  const login = async () => {
    try {
      setLoading(true);
      const { data } = await loginWithSmsToken(phoneNumber, smsCode);
      localStorage.setItem('authToken', data.authToken);
      API.defaults.headers.common.Authorization = `Bearer ${data.authToken}`;
      dispatch(fetchUserProfile(data.me));
      if (
        (selectedClub === null || typeof selectedClub === 'undefined') &&
        data?.me?.clubs.length > 0
      ) {
        dispatch(updateSelectedClub(data.me.clubs[0]));
      } else {
        const userStillHasSameClub = checkIfUserStillHasClub(
          selectedClub,
          data.me.clubs,
        );
        if (!userStillHasSameClub) {
          dispatch(updateSelectedClub(data.me.clubs[0]));
        }
      }
      if (data.me.clubs) {
        dispatch(updateAllClubs(data.me.clubs));
      }
      navigate(from, { replace: true });
    } catch (err) {
      // const { error } = err.response.data;
      // console.log('ERR', err);
      toast.error('Henting av nødvendig informasjon feilet!');
    } finally {
      setLoading(false);
    }
  };

  const handleOnSubmit = event => {
    event.preventDefault();
    if (codeReceived) {
      login();
    } else {
      requestSms();
    }
  };

  return (
    <div className="loginPageConatiner">
      <div className="loginContainer">
        <h1 className="loginHeaderText">Logg inn</h1>
        <form className="loginContainer" onSubmit={handleOnSubmit}>
          <TextField
            autoFocus
            className="loginInput"
            variant="outlined"
            label="Telefonnummer"
            onChange={onPhoneNumberChange}
          />
        </form>
        {codeReceived && (
          <form className="loginContainer" onSubmit={handleOnSubmit}>
            <TextField
              autoFocus
              className="loginInput"
              variant="outlined"
              label="SMS-Kode"
              onChange={onSmsCodeChange}
            />
          </form>
        )}
        <Button
          variant="contained"
          size="large"
          color="primary"
          type="submit"
          onClick={handleOnSubmit}
          disabled={
            phoneNumber === '' || (codeReceived && smsCode === '') || loading
          }
        >
          {!loading ? 'LOGG INN' : <CircularProgress size={26} />}
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedClub: state.clubsReducer.selectedClub,
});

export default connect(mapStateToProps)(Login);
