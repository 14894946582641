const initialState = {
  pagePosition: false,
};

export default function pagePositionReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_PAGEPOSITION':
      return {
        ...state,
        pagePosition: action.value,
      };

    default:
      return state;
  }
}
