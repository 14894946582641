import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home, Login, MyPage } from 'pages';
import Topbar from '../../components/Topbar/Topbar';
import ProductListModal from '../../components/ProductListModal/ProductListModal';
import PrivateRoute from './PrivateRoute';

import '../../styles/layout.scss';

function WrappedRoute({ Component }) {
  return (
    <div className="layoutWrapper">
      <div className="layout">
        <Topbar className="topbar" />
        <ProductListModal />
        {Component}
      </div>
    </div>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/log_in" element={<Login />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <WrappedRoute Component={<MyPage />} />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <WrappedRoute Component={<Home />} />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
