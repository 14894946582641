import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, arrayOf, func, oneOfType, bool } from 'prop-types';
import './styles.scss';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import posed from 'react-pose';
import CheckIcon from 'mdi-react/CheckIcon';
import { updateMainMenuState } from '../../actions/mainMenuActions';
import { updateSelectedCategory } from '../../actions/categoryActions';

const propTypes = {
  pagePosition: oneOfType([bool, shape({})]),
  dispatch: func.isRequired,
  selectedCategory: shape({}),
  allCategories: arrayOf(shape({})),
};

const defaultProps = {
  selectedCategory: {},
  allCategories: [],
  pagePosition: false,
};

const DropDownItem = posed.div({
  hovering: {
    backgroundColor: '#e1e1e1',
    fontWeight: 300,
  },
  notHovering: {
    backgroundColor: '#ffffff',
    fontWeight: 300,
  },
});

const DropDown = posed.div({
  open: {
    height: 'auto',
  },

  closed: {
    height: 0,
  },
});

const ArrowIcon = posed.div({
  up: {
    rotate: '180deg',
  },
  down: {
    rotate: '0deg',
  },
});

class CatSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentDidUpdate(prevProps) {
    const { pagePosition } = this.props;
    if (
      pagePosition &&
      pagePosition !== prevProps.pagePosition &&
      !prevProps.navbar
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        open: false,
      });
    }

    if (
      !pagePosition &&
      pagePosition !== prevProps.pagePosition &&
      prevProps.navbar
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        open: false,
      });
    }

    // Handle store select - reset filter
    // commenting out while migrating to router v6
    // as match API has changed and filters are not being currently used
    /*     if (prevProps.match.params.id !== match.params.id) {
      this.handleAllPick();
    } */
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  changePickedCat = (cat, index) => {
    this.setState({ open: false }, () => {});
    this.onMeniuPick(cat, index);
  };

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({ open: false });
  };

  onMeniuPick = cat => {
    const { dispatch } = this.props;
    dispatch(updateMainMenuState(false));
    dispatch(updateSelectedCategory(cat));
  };

  handleAllPick = () => {
    const { dispatch } = this.props;
    this.setState({ open: false }, () => {});
    const allPickObj = {
      id: '',
      name: 'Alle produkter',
    };
    dispatch(updateMainMenuState(false));
    dispatch(updateSelectedCategory(allPickObj));
  };

  render() {
    const { open } = this.state;
    const { selectedCategory, allCategories } = this.props;
    return (
      // eslint-disable-next-line no-return-assign
      <div className="sort" ref={node => (this.node = node)}>
        <button
          onClick={() => this.setState({ open: !open })}
          className="text homePickerText"
          type="button"
        >
          {selectedCategory.name === ''
            ? 'Alle produkter'
            : selectedCategory.name}
          <ArrowIcon className="iconDiv" pose={open ? 'up' : 'down'}>
            <ChevronDownIcon className="icon" />
          </ArrowIcon>
        </button>
        <DropDown
          pose={open ? 'open' : 'closed'}
          className="dropDown dropDownWidth deactivateScroll"
        >
          <DropDownItem
            key="stockID99"
            onClick={() => this.handleAllPick()}
            className="dropDownItem"
          >
            <div className="dropDownLastItem">Alle produkter</div>
            {selectedCategory.name === 'Alle produkter' ? (
              <div className="checkIcon">
                <CheckIcon />
              </div>
            ) : null}
          </DropDownItem>
          {allCategories.map((cat, index) => (
            <DropDownItem
              key={cat.id}
              onClick={() => this.changePickedCat(cat, index)}
              className="dropDownItem"
            >
              {cat.name}
              {selectedCategory.name === cat.name ? (
                <div className="checkIcon">
                  <CheckIcon />
                </div>
              ) : null}
            </DropDownItem>
          ))}
        </DropDown>
      </div>
    );
  }
}

CatSelector.propTypes = propTypes;
CatSelector.defaultProps = defaultProps;

const mapStateToProps = state => ({
  pagePosition: state.pagePositionReducer.pagePosition,
  allCategories: state.categoriesReducer.allCategories,
  selectedCategory: state.categoriesReducer.selectedCategory,
});

export default connect(mapStateToProps)(CatSelector);
