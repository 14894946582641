export function updateMainMenuState(value) {
  return {
    type: 'UPDATE_MAIN_MENU_STATE',
    value,
  };
}

export function updateTotalProductCount(value) {
  return {
    type: 'UPDATE_TOTAL_PRODUCT_COUNT',
    value,
  };
}
