export function updateAllCategories(value) {
  return {
    type: 'UPDATE_ALL_CATEGORIES',
    value,
  };
}

export function updateSelectedCategory(value) {
  return {
    type: 'UPDATE_SELECTED_CATEGORY',
    value,
  };
}
