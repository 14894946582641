import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import './styles.scss';

function ActionModal({
  toggleShowActionModal,
  showActionModal,
  modalText,
  explanationText,
  actionButtonText,
  actionForModal,
  cancelButtonText,
  showCancelButton = true,
}) {
  return (
    <Dialog open={showActionModal} onClose={() => toggleShowActionModal()}>
      <div className="modal-container">
        <div className="modal-title-text">{modalText}</div>
        <div className="modal-explanation-text">{explanationText}</div>
        <div className="modal-button-container">
          <div className="modal-button-container">
            <Button
              variant="contained"
              color="primary"
              onClick={() => actionForModal()}
            >
              {actionButtonText}
            </Button>
          </div>
          {showCancelButton ? (
            <div className="modal-button-container-cancel">
              <Button
                variant="contained"
                onClick={() => toggleShowActionModal()}
              >
                {cancelButtonText}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
}

ActionModal.propTypes = {
  toggleShowActionModal: PropTypes.func,
  showActionModal: PropTypes.bool.isRequired,
  modalText: PropTypes.string,
  explanationText: PropTypes.string,
  actionButtonText: PropTypes.string,
  actionForModal: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  showCancelButton: PropTypes.bool,
};

ActionModal.defaultProps = {
  modalText: '',
  explanationText: '',
  actionButtonText: 'Ja',
  cancelButtonText: 'Lukk',
  showCancelButton: true,
  toggleShowActionModal: () => {},
};

export default ActionModal;
