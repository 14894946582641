/* eslint-disable global-require */
import React, { Component } from 'react';
import './styles.scss';
import posed from 'react-pose';
import MdiPlusIcon from 'mdi-react/PlusIcon';
import { connect } from 'react-redux';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { shape, arrayOf, func, bool } from 'prop-types';
import toggleProductListModal from '../../actions/productListActions';
import {
  resetBasket,
  updateBasket,
  increaseProductCount,
  decreaseProductCount,
} from '../../actions/shoppingCartActions';
import MinusIcon from '../../images/Minus.svg';
import PlusIcon from '../../images/Plus.svg';
import TrashIcon from '../../images/trash.svg';

import Loader from '../Loader/Loader';
import ListPersonalizations from './ListPersonalizations';
import { countProductsInCart } from '../../util/methods';
import ContactInformationAndActions from './ContactInformationAndActions';
import OrderSentMessage from './OrderSentMessage';

const propTypes = {
  cartItems: arrayOf(shape({})),
  dispatch: func.isRequired,
  productListModal: bool,
};

const defaultProps = {
  cartItems: [],
  productListModal: false,
};

const Shade = posed.div({
  enter: {
    opacity: 1,
    overflow: 'visible',
    top: 0,
    height: '100%',
    transition: {
      height: { duration: 0 },
      top: { duration: 0 },
    },
    backgroundColor: 'rgba(255,255,255,.9)',
  },
  exit: {
    opacity: 0,
    overflow: 'hidden',
    top: '-800px',
    height: '0',
    transition: {
      height: { duration: 0, delay: 150 },
      top: { duration: 0, delay: 150 },
    },
    backgroundColor: 'rgba(255,255,255,0)',
  },
});

const CornerCloseDiv = posed.div({
  hovering: {
    rotate: '90deg',
  },
  notHovering: {
    rotate: '0deg',
  },
});

const ArrowIcon = posed.div({
  open: {
    rotate: '0deg',
  },
  closed: {
    rotate: '-90deg',
  },
});

const PersonalizationDropdown = posed.div({
  open: {
    height: 'auto',
  },
  closed: {
    height: 0,
  },
});

class ProductListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      activeDropdowns: [],
      loading: false,
    };
  }

  closeModal = e => {
    const { dispatch } = this.props;
    if (e.target.id) {
      dispatch(toggleProductListModal(false));
    }
  };

  mouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  mouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  removeProduct = productID => {
    const { cartItems, dispatch } = this.props;
    let tmpProductList = [...cartItems];
    tmpProductList = tmpProductList.filter(product => product.id !== productID);
    dispatch(updateBasket(tmpProductList));
  };

  adjustAmount = (productId, action) => {
    const { cartItems, dispatch } = this.props;
    const tmpProductList = [...cartItems];
    tmpProductList.forEach(product => {
      if (product.id === productId) {
        if (action === 'add') {
          dispatch(increaseProductCount(product.id));
        } else if (action === 'remove' && product.quantity > 1) {
          dispatch(decreaseProductCount(product.id));
        }
      }
    });
  };

  dropDownHandler = id => {
    const { activeDropdowns } = this.state;
    let arr = activeDropdowns;
    if (activeDropdowns.includes(id)) {
      arr = arr.filter(e => e !== id);
    } else {
      arr.push(id);
    }
    this.setState({
      activeDropdowns: arr,
    });
  };

  toggleLoadingState = value => {
    this.setState({ loading: value });
  };

  render() {
    const { productListModal, cartItems, dispatch } = this.props;
    const { loading, hover, activeDropdowns } = this.state;
    return (
      <Shade
        pose={productListModal ? 'enter' : 'exit'}
        id="productListModal-close"
        className="productListModal"
      >
        <Loader cover state={loading} />
        <div
          id="productListModal-container"
          className="productListModal-container"
        >
          <div className="productListModal-container-content">
            <div className="productListModal-header">
              <h1>
                Produktliste (
                {cartItems.length > 1 ||
                cartItems[0]?.quantity > 1 ||
                cartItems?.length === 0
                  ? `${countProductsInCart(cartItems)} Varer`
                  : `${countProductsInCart(cartItems)} Vare`}
                )
              </h1>
              <div>
                <button onClick={() => dispatch(resetBasket())} type="button">
                  <img src={TrashIcon} alt="" />
                  &nbsp;Tøm liste
                </button>
                <CornerCloseDiv
                  pose={hover ? 'hovering' : 'notHovering'}
                  id="productListModal-cornerButton"
                  onClick={this.closeModal}
                  className="closeModal"
                >
                  <MdiPlusIcon
                    id="productListModal-cornerIcon"
                    className="closeIcon"
                  />
                  <div
                    onMouseEnter={this.mouseEnter}
                    onMouseLeave={this.mouseLeave}
                    id="productListModal-corner"
                    className="iconFiller"
                  />
                </CornerCloseDiv>
              </div>
            </div>
            <OrderSentMessage />
            <div className="productListModal-wrapItems">
              {cartItems.length !== 0 ? (
                <div className="productListModal-title">
                  <div className="productListModal-title-item-left" />
                  <div className="productListModal-title-item-right">
                    <div className="productListModal-title-item-right-size">
                      Str
                    </div>
                    <div className="productListModal-title-item-right-amount">
                      Antall
                    </div>
                    <div className="flex05" />
                  </div>
                </div>
              ) : null}
              <div className="productListModal-body">
                {cartItems.length !== 0 &&
                  cartItems.map(product => {
                    const isReduceButtonDisabled = () => {
                      const personalizationsQuantity =
                        product?.activePersonalizations.reduce(
                          (acc, currentItem) => {
                            if (typeof currentItem?.quantity === 'number') {
                              const combinedAmount = acc + currentItem.quantity;
                              return combinedAmount;
                            }
                            return acc;
                          },
                          0,
                        );

                      const parsedQuantity =
                        typeof parseInt(product.quantity, 10) === 'number'
                          ? parseInt(product.quantity, 10)
                          : product.quantity;

                      // eslint-disable-next-line max-len
                      const productsWithoutPersonalization =
                        parsedQuantity - personalizationsQuantity;
                      if (typeof productsWithoutPersonalization !== 'number') {
                        return true;
                      }
                      if (productsWithoutPersonalization > 0) {
                        return false;
                      }
                      return true;
                    };
                    return (
                      <div key={`product-${product.id}`}>
                        <div className="productListModal-item-wrapper">
                          <div className="productListModal-item">
                            <div className="productListModal-item-left">
                              <button
                                className="productListModal-dropdown-arrow"
                                onClick={() => this.dropDownHandler(product.id)}
                                type="button"
                              >
                                <ArrowIcon
                                  className="iconDiv"
                                  pose={
                                    !activeDropdowns.includes(product.id)
                                      ? 'open'
                                      : 'closed'
                                  }
                                >
                                  <ChevronDownIcon className="icon" />
                                </ArrowIcon>
                              </button>

                              <img
                                className="productListModal-item-image"
                                src={
                                  product.text.images.length !== 0 &&
                                  `${product.text.images[0].url}550x550`
                                }
                                alt=""
                              />
                              <div className="productListModal-item-description">
                                <div className="productListModal-item-text-container">
                                  <div className="productListModal-item-text">
                                    <p className="productListModal-item-description-brand">
                                      {product.text.brand.name}
                                    </p>
                                    <p className="productListModal-item-description-name">
                                      {product.text.name}
                                    </p>
                                  </div>
                                  <div className="productListModal-item-description-toggleWord">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.dropDownHandler(product.id)
                                      }
                                      className="productListModal-item-description-toggleWord-button"
                                    >
                                      LEGG TIL PERSONALISERING
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="productListModal-item-right">
                              <div className="size-text">
                                {product.size.size}
                              </div>
                              <div className="amount-container">
                                <button
                                  type="button"
                                  className="adjustAmountContainer"
                                  onClick={() =>
                                    this.adjustAmount(product.id, 'remove')
                                  }
                                  disabled={
                                    parseInt(product.quantity, 10) === 1 ||
                                    isReduceButtonDisabled()
                                  }
                                >
                                  <img
                                    src={MinusIcon}
                                    alt="Reduce quantity"
                                    className="quantity-icons"
                                  />
                                </button>
                                <div className="amount-number-container">
                                  <div className="amount-number">
                                    {product.quantity}
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="adjustAmountContainer"
                                  onClick={() =>
                                    this.adjustAmount(product.id, 'add')
                                  }
                                >
                                  <img
                                    src={PlusIcon}
                                    alt="Add quantity"
                                    className="quantity-icons"
                                  />
                                </button>
                              </div>
                              <div className="trashIcon-wrapper">
                                <button
                                  onClick={() => this.removeProduct(product.id)}
                                  type="button"
                                  className="trashIconContainer"
                                >
                                  <img
                                    src={TrashIcon}
                                    alt="trash"
                                    className="trashIcon"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="order-info-line-mobile">
                            <div className="productListModal-item-text">
                              <div className="productListModal-item-description-brand-mobile">
                                {product.text.brand.name}
                              </div>
                              <div className="productListModal-item-description-name-mobile">
                                {product.text.name}
                              </div>
                              <div className="size-text-mobile">
                                {`Str: ${product.size.size}`}
                              </div>
                              {!activeDropdowns.includes(product.id) ? (
                                <button
                                  className="add-pers-button-mobile"
                                  onClick={() =>
                                    this.dropDownHandler(product.id)
                                  }
                                  type="button"
                                >
                                  Legg til personalisering
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <PersonalizationDropdown
                          pose={
                            activeDropdowns.includes(product.id)
                              ? 'open'
                              : 'closed'
                          }
                          className="productListModal-personalization-products"
                        >
                          <div
                            className={
                              activeDropdowns.includes(product.id)
                                ? 'productListModal-person-item'
                                : 'productListModal-person-item-inactive'
                            }
                          >
                            <ListPersonalizations
                              availablePersonalizations={
                                product.availablePersonalizations
                              }
                              activePersonalizations={
                                product.activePersonalizations
                              }
                              variant={product.size}
                              quantity={product.quantity}
                              cartItemId={product.id}
                              size={product.size.size}
                              adjustAmount={this.adjustAmount}
                            />
                          </div>
                        </PersonalizationDropdown>
                      </div>
                    );
                  })}
              </div>
            </div>

            <ContactInformationAndActions
              toggleLoadingState={value => this.toggleLoadingState(value)}
              loading={loading}
            />
          </div>
        </div>
      </Shade>
    );
  }
}

ProductListModal.propTypes = propTypes;
ProductListModal.defaultProps = defaultProps;

const mapStateToProps = state => ({
  productList: state.productListReducer.productList,
  productListModal: state.productListReducer.productListModal,
  cartItems: state.shoppingCartReducer.cartItems,
});

export default connect(mapStateToProps)(ProductListModal);
