import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import userReducer from './reducers/userReducer';
import productListReducer from './reducers/productListReducer';
import pagePositionReducer from './reducers/pagePositionReducer';
import sortByPriceReducer from './reducers/sortByPriceReducer';
import mainMenuReducer from './reducers/mainMenuReducer';
import brandsReducer from './reducers/brandsReducer';
import categoriesReducer from './reducers/categoriesReducer';
import shoppingCartReducer from './reducers/shoppingCartReducer';
import clubsReducer from './reducers/clubsReducer';

const rootReducer = combineReducers({
  userReducer,
  productListReducer,
  pagePositionReducer,
  sortByPriceReducer,
  mainMenuReducer,
  brandsReducer,
  categoriesReducer,
  shoppingCartReducer,
  clubsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

const persistor = persistStore(store);
export { persistor, store };
