const initialState = {
  price: '-price',
};

export default function sortByPriceReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_SORT_BY_PRICE':
      return {
        ...state,
        price: action.value,
      };

    default:
      return state;
  }
}
