/* eslint-disable global-require */
import React, { Component } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { shape, arrayOf, func, number } from 'prop-types';
import { TextField, Button } from '@mui/material';
import {
  changePersonalizationItem,
  removePersonalizationItem,
  addPersonalizationItemCollection,
  increaseProductCount,
  adjustPersonalizationCount,
  decreaseProductCountByNumber,
} from '../../actions/shoppingCartActions';
import MinusIcon from '../../images/Minus.svg';
import PlusIcon from '../../images/Plus.svg';
import TrashIcon from '../../images/trash.svg';

const propTypes = {
  activePersonalizations: arrayOf(shape({})),
  cartItemId: number.isRequired,
  dispatch: func.isRequired,
  cartItems: arrayOf(shape({})),
  adjustAmount: func,
};

const defaultProps = {
  activePersonalizations: [],
  cartItems: [],
  adjustAmount: () => {},
};

class ListPersonalizations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // used to toggle if line is active for render
      activePersonalizationFields: [],
      numberOfPersonalizedProducts: 0,
      currentCartItem: {},
      // eslint-disable-next-line react/no-unused-state
      windowWidth: 0,
    };
  }

  componentDidMount() {
    const { cartItemId, cartItems } = this.props;
    if (cartItems?.length > 0 && typeof cartItemId === 'number') {
      const findCurrentCart = cartItems.find(
        cartItem => cartItem?.id === cartItemId,
      );
      if (findCurrentCart !== undefined) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ currentCartItem: findCurrentCart });
        this.findCurrentProduct();
      }
    }

    window.addEventListener('resize', this.getDimensions);
  }

  componentDidUpdate(prevProps) {
    const { cartItemId, cartItems } = this.props;
    if (
      prevProps.cartItems !== cartItems &&
      cartItems?.length > 0 &&
      prevProps.cartItemId === cartItemId
    ) {
      this.findCurrentProduct();
      const findCurrentCart = cartItems.find(
        cartItem => cartItem?.id === cartItemId,
      );
      if (findCurrentCart !== undefined) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ currentCartItem: findCurrentCart });
      }
    } else if (
      cartItems?.length > 0 &&
      typeof cartItemId === 'number' &&
      cartItemId !== prevProps.cartItemId
    ) {
      const findCurrentCart = cartItems.find(
        cartItem => cartItem?.id === cartItemId,
      );
      if (findCurrentCart !== undefined) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ currentCartItem: findCurrentCart });
      }
    }
  }

  // remove listener on page exit
  componentWillUnmount() {
    window.removeEventListener('resize', this.getDimensions);
  }

  getDimensions = () => {
    if (typeof window?.innerWidth === 'number') {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ windowWidth: window.innerWidth });
    }
  };

  findCurrentProduct = () => {
    const { cartItemId, cartItems } = this.props;
    const tmpProductList = [...cartItems];
    tmpProductList.forEach(product => {
      if (product.id === cartItemId) {
        // eslint-disable-next-line max-len
        const numberOfPersonalizedProductsCount =
          product?.activePersonalizations.reduce((acc, currentItem) => {
            if (typeof currentItem?.quantity === 'number') {
              const combinedAmount = acc + currentItem.quantity;
              return combinedAmount;
            }
            return acc;
          }, 0);
        this.setState({
          numberOfPersonalizedProducts: numberOfPersonalizedProductsCount,
        });
      }
    });
  };

  removeActive = id => {
    const { activePersonalizationFields } = this.state;
    let arr = activePersonalizationFields;
    if (activePersonalizationFields.includes(id)) {
      arr = arr.filter(e => e !== id);
    }
    this.setState({
      activePersonalizationFields: arr,
    });
  };

  removePersonalization = (cartItemId, personalizationId) => {
    const { dispatch } = this.props;

    dispatch(removePersonalizationItem(cartItemId, personalizationId));
    this.removeActive(personalizationId);
  };

  onPersonalizationValueChange = (
    cartItemId,
    personalizationId,
    personalizationItemId,
    personalizationType,
    value,
  ) => {
    const intNumber = parseInt(value, 10);
    if ((typeof intNumber === 'number' && intNumber >= 0) || value === '') {
      const { dispatch } = this.props;
      if (value !== null) {
        dispatch(
          changePersonalizationItem(
            cartItemId,
            personalizationId,
            personalizationItemId,
            personalizationType,
            value,
          ),
        );
      }
    } else {
      const { dispatch } = this.props;
      if (value !== null) {
        dispatch(
          changePersonalizationItem(
            cartItemId,
            personalizationId,
            personalizationItemId,
            personalizationType,
            value,
          ),
        );
      }
    }
  };

  adjustNumberTo = (cartItemId, personalizationItemId, actionType) => {
    // in case of interval - check if "Nummer til" needs to be increased or decreased
    const { currentCartItem } = this.state;
    const { dispatch } = this.props;
    if (
      currentCartItem?.activePersonalizations?.length > 0 &&
      typeof personalizationItemId === 'number'
    ) {
      const activePersonaliztionItem =
        currentCartItem.activePersonalizations.find(
          persItem => persItem?.id === personalizationItemId,
        );

      if (
        activePersonaliztionItem !== undefined &&
        activePersonaliztionItem?.items?.length > 0
      ) {
        const objOfNumberToPersonalization =
          activePersonaliztionItem?.items.find(
            perItem => perItem?.name === 'Nummer til',
          );
        if (typeof objOfNumberToPersonalization?.id === 'number') {
          const objOfNumberFromPersonalization =
            activePersonaliztionItem?.items.find(
              perItem => perItem?.name === 'Nummer fra',
            );
          // find new highest number
          if (
            objOfNumberFromPersonalization !== undefined &&
            typeof activePersonaliztionItem?.quantity === 'number'
          ) {
            const numberFrom =
              objOfNumberFromPersonalization?.value?.length > 0
                ? objOfNumberFromPersonalization?.value
                : '';
            const parsedNumberFrom = parseInt(numberFrom, 10);
            if (parsedNumberFrom >= 0) {
              let newNumberTo =
                parsedNumberFrom + activePersonaliztionItem.quantity - 1;

              if (actionType === 'increase') {
                newNumberTo += 1;
              } else {
                newNumberTo -= 1;
              }
              dispatch(
                changePersonalizationItem(
                  cartItemId,
                  objOfNumberToPersonalization?.id,
                  personalizationItemId,
                  'Nummer til',
                  newNumberTo,
                ),
              );
            }
          }
        }
      }
    }
  };

  onAdjustPersonalizationAmount = (cartItemId, personalizationItemId, type) => {
    const { dispatch } = this.props;
    dispatch(
      adjustPersonalizationCount(cartItemId, personalizationItemId, type),
    );
    if (type === 'increase') {
      const { currentCartItem, numberOfPersonalizedProducts } = this.state;
      // check if total number needs to be increased
      if (
        typeof numberOfPersonalizedProducts === 'number' &&
        typeof currentCartItem?.quantity === 'number' &&
        currentCartItem?.quantity <= numberOfPersonalizedProducts
      ) {
        dispatch(increaseProductCount(currentCartItem.id));
      }
    }
    this.adjustNumberTo(cartItemId, personalizationItemId, type);
  };

  onPersonalizationFromNumberChange = (
    cartItemId,
    personalizationId,
    personalizationItemId,
    personalizationType,
    value,
  ) => {
    const { dispatch } = this.props;
    const { currentCartItem } = this.state;
    const intNumber = parseInt(value, 10);
    if ((typeof intNumber === 'number' && intNumber >= 0) || value === '') {
      dispatch(
        changePersonalizationItem(
          cartItemId,
          personalizationId,
          personalizationItemId,
          personalizationType,
          value,
        ),
      );

      const personalizationToChange =
        currentCartItem?.activePersonalizations.find(
          personItem => personItem?.id === personalizationItemId,
        );
      if (personalizationToChange !== undefined) {
        const numberToPersonalization = personalizationToChange?.items.find(
          itemToSearch => itemToSearch?.name === 'Nummer til',
        );
        if (typeof numberToPersonalization?.id === 'number') {
          // Change automatically value of "Number to"
          const lastNumber =
            parseInt(value, 10) + personalizationToChange.quantity - 1;

          if (typeof lastNumber === 'number') {
            dispatch(
              changePersonalizationItem(
                cartItemId,
                numberToPersonalization?.id,
                personalizationItemId,
                'Nummer til',
                lastNumber,
              ),
            );
          }
        }
      }
    }
  };

  renderPersonalizationsTitleLine = () => {
    const { activePersonalizations } = this.props;

    if (
      activePersonalizations !== undefined &&
      activePersonalizations?.length === 0
    ) {
      return null;
    }

    return (
      <div className="personalizations-container product-list-personalizations-title">
        <div className="personalizations-container-left">
          <div className="product-list-personalizations-container">
            <div className="product-list-personalization-item-name">Navn</div>
            <div className="product-list-personalization-item-initials">
              Initialer
            </div>
            <div className="product-list-personalization-item-number">
              Nummer
            </div>
          </div>
        </div>
        <div className="personalizations-container-right-personalizations">
          <div className="productListModal-pers-title-item-right-size" />
          <div className="productListModal-title-item-right-amount">Antall</div>
          <div className="flex05" />
        </div>
      </div>
    );
  };

  removeProductsWithoutPersonalization = amountToDecrease => {
    const { dispatch, cartItemId } = this.props;
    dispatch(decreaseProductCountByNumber(cartItemId, amountToDecrease));
  };

  // eslint-disable-next-line class-methods-use-this
  findIfDecreaseButtonDeactivate = currentPersonalization => {
    if (
      currentPersonalization?.items?.length > 0 &&
      currentPersonalization?.quantity >= 2
    ) {
      const isLineAnInterval = currentPersonalization?.items.find(
        item => item.name === 'Nummer fra',
      );
      if (
        isLineAnInterval !== undefined &&
        currentPersonalization?.quantity <= 2
      ) {
        return true;
      }
      return false;
    }
    if (currentPersonalization?.quantity < 2) {
      return true;
    }
    return false;
  };

  listPersonalizations = () => {
    const { activePersonalizations, cartItemId } = this.props;

    let isLastColored = false;
    let isLastItem = false;

    if (activePersonalizations.length > 0) {
      return activePersonalizations.map((per, index) => {
        if (activePersonalizations?.length === index + 1) {
          isLastItem = true;
          isLastColored = index % 2 === 0;
        }

        return (
          <div key={per?.id}>
            <div
              className={
                index % 2 === 0
                  ? 'personalizations-container-colored'
                  : 'personalizations-container'
              }
            >
              <div className="personalizations-container-left">
                <div className="personalization-padding" />

                {per?.items?.map(item => {
                  // Due to new changes and legacy architecture, handle changes to number from
                  // and number to by detecting number from and returning both numbers
                  if (item?.name === 'Nummer til') {
                    return null;
                  }
                  if (item?.name === 'Nummer fra') {
                    return (
                      <div
                        key={item?.id}
                        className="personalization-item-container"
                      >
                        <TextField
                          size="small"
                          // label={this.state.windowWidth <= 600 ? "Nr.fra" : ""}
                          className="personalization-input-collect-nr"
                          type="number"
                          variant="outlined"
                          value={item?.value !== '' ? item.value : ''}
                          onChange={e =>
                            this.onPersonalizationFromNumberChange(
                              cartItemId,
                              item.id,
                              per.id,
                              item.name,
                              e.target.value,
                            )
                          }
                        />
                        <div className="product-list-personalization-numbers-to-text">
                          til
                        </div>
                        <TextField
                          size="small"
                          // label={this.state.windowWidth <= 600 ? "Nr.til" : ""}
                          className="personalization-input-collect-nr"
                          disabled
                          variant="outlined"
                          value={
                            per?.items?.find(
                              mapItem => mapItem.name === 'Nummer til',
                            )?.value || ''
                          }
                        />
                      </div>
                    );
                  }
                  if (item !== undefined) {
                    return (
                      <div
                        key={`${item?.id}`}
                        className="personalization-item-container"
                      >
                        <TextField
                          size="small"
                          label={item?.name ? item.name : ''}
                          type={item?.name === 'Nummer' ? 'number' : ''}
                          className="personalization-input"
                          variant="outlined"
                          value={item !== undefined ? item?.value : ''}
                          onChange={e =>
                            this.onPersonalizationValueChange(
                              cartItemId,
                              item.id,
                              per.id,
                              item.name,
                              e.target.value,
                            )
                          }
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
              <div className="personalizations-container-right-personalizations">
                <div className="item-quantity">
                  <div className="amount-container-personalization">
                    <div className="emptyItem-personalization" />
                    <button
                      type="button"
                      className="adjustAmountContainer"
                      disabled={this.findIfDecreaseButtonDeactivate(per)}
                      onClick={() =>
                        this.onAdjustPersonalizationAmount(
                          cartItemId,
                          per.id,
                          'decrease',
                        )
                      }
                    >
                      <img
                        src={MinusIcon}
                        alt="Reduce quantity"
                        className="quantity-icons"
                      />
                    </button>
                    <div className="amount-number-container">
                      <div className="amount-number">{per?.quantity}</div>
                    </div>
                    <button
                      type="button"
                      className="adjustAmountContainer"
                      onClick={() =>
                        this.onAdjustPersonalizationAmount(
                          cartItemId,
                          per.id,
                          'increase',
                        )
                      }
                    >
                      <img
                        src={PlusIcon}
                        alt="Add quantity"
                        className="quantity-icons"
                      />
                    </button>
                  </div>
                  <button
                    onClick={() =>
                      this.removePersonalization(cartItemId, per.id)
                    }
                    type="button"
                    className="trashIconContainer-personalization"
                  >
                    <img src={TrashIcon} alt="remove personalized product" />
                  </button>
                </div>
              </div>
            </div>
            {this.listLineWithoutPersonalizations(isLastColored, isLastItem)}
          </div>
        );
      });
    }
    return null;
  };

  handleCreatePersonalizations = useNumberCollection => {
    const { cartItems, dispatch, cartItemId } = this.props;
    const { numberOfPersonalizedProducts, currentCartItem } = this.state;
    const tmpProductList = [...cartItems];
    tmpProductList.forEach(product => {
      if (product.id === cartItemId) {
        const intQuantity =
          typeof product?.quantity === 'string'
            ? parseInt(product?.quantity, 10)
            : product?.quantity;

        if (useNumberCollection === true && typeof intQuantity === 'number') {
          const quantityWithoutPersonalizations =
            intQuantity - numberOfPersonalizedProducts;

          if (
            typeof quantityWithoutPersonalizations === 'number' &&
            quantityWithoutPersonalizations > 1
          ) {
            dispatch(
              addPersonalizationItemCollection(
                product.id,
                product.availablePersonalizationsForSerializing,
                quantityWithoutPersonalizations,
              ),
            );
          }
        } else {
          dispatch(
            addPersonalizationItemCollection(
              product.id,
              product.availablePersonalizations,
              1,
            ),
          );
          if (
            typeof product?.quantity === 'number' &&
            typeof numberOfPersonalizedProducts === 'number' &&
            numberOfPersonalizedProducts >= product.quantity &&
            typeof currentCartItem?.id === 'number'
          ) {
            dispatch(increaseProductCount(currentCartItem.id));
          }
        }
      }
    });
  };

  listLineWithoutPersonalizations = (isLastColored, isLastItem) => {
    const { numberOfPersonalizedProducts, currentCartItem } = this.state;
    const { cartItemId, adjustAmount } = this.props;
    if (!isLastItem) {
      return null;
    }
    if (
      currentCartItem?.quantity > 0 &&
      typeof currentCartItem.quantity === 'number' &&
      currentCartItem?.quantity > numberOfPersonalizedProducts
    ) {
      const numberWithoutPersonalization =
        currentCartItem.quantity - numberOfPersonalizedProducts;
      return (
        <div
          className={
            !isLastColored
              ? 'personalizations-container-colored'
              : 'personalizations-container'
          }
        >
          <div className="personalizations-container-left">
            <div className="no-personalized-products-text">
              Varer uten personalisering
            </div>
          </div>
          <div className="personalizations-container-right-personalizations">
            <div className="item-quantity">
              <div className="amount-container-personalization">
                <div className="emptyItem-personalization" />
                <button
                  type="button"
                  className="adjustAmountContainer"
                  onClick={() => adjustAmount(cartItemId, 'remove')}
                >
                  <img
                    src={MinusIcon}
                    alt="Reduce quantity"
                    className="quantity-icons"
                  />
                </button>
                <div className="amount-number-container">
                  <div className="amount-number">
                    {numberWithoutPersonalization}
                  </div>
                </div>
                <button
                  type="button"
                  className="adjustAmountContainer"
                  onClick={() => adjustAmount(cartItemId, 'add')}
                >
                  <img
                    src={PlusIcon}
                    alt="Add quantity"
                    className="quantity-icons"
                  />
                </button>
              </div>
              <button
                onClick={() =>
                  this.removeProductsWithoutPersonalization(
                    numberWithoutPersonalization,
                  )
                }
                type="button"
                className="trashIconContainer-personalization"
              >
                <img src={TrashIcon} alt="remove personalized product" />
              </button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const { currentCartItem, numberOfPersonalizedProducts } = this.state;

    const disableSeriesPersonalizations = () => {
      const parsedCartQuantity = parseInt(currentCartItem?.quantity, 10);
      if (
        typeof parsedCartQuantity === 'number' &&
        typeof numberOfPersonalizedProducts === 'number'
      ) {
        if (parsedCartQuantity - numberOfPersonalizedProducts > 1) {
          return false;
        }
        return true;
      }
      return true;
    };

    return (
      <div>
        {this.renderPersonalizationsTitleLine()}
        {this.listPersonalizations()}
        <div className="add-new-personalization-button">
          <Button onClick={() => this.handleCreatePersonalizations(false)}>
            <div className="add-new-personaliztion-text">
              +Ny personalisering
            </div>
          </Button>
          <Button
            disabled={disableSeriesPersonalizations()}
            onClick={() => this.handleCreatePersonalizations(true)}
            className={
              disableSeriesPersonalizations()
                ? 'disabled-add-personalization-button'
                : ''
            }
          >
            <div className="add-new-personaliztion-text">
              +Ny Personalisering - løpende nr.
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

ListPersonalizations.propTypes = propTypes;
ListPersonalizations.defaultProps = defaultProps;

const mapStateToProps = state => ({
  cartItems: state.shoppingCartReducer.cartItems,
});

export default connect(mapStateToProps)(ListPersonalizations);
