const initialState = {
  mainMenuState: '',
  totalProducts: 0,
};

export default function mainMenuReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_MAIN_MENU_STATE':
      return {
        ...state,
        mainMenuState: action.value,
      };

    case 'UPDATE_TOTAL_PRODUCT_COUNT':
      return {
        ...state,
        totalProducts: action.value,
      };

    default:
      return state;
  }
}
