const initialState = {
  allClubs: [],
  selectedClub: {},
};

export default function brandsReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_ALL_CLUBS':
      return {
        ...state,
        allClubs: action.value,
      };
    case 'UPDATE_SELECTED_CLUB':
      return {
        ...state,
        selectedClub: action.value,
      };
    default:
      return state;
  }
}
