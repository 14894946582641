import React from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function ClubSelect({
  selectedClub,
  allClubs,
  cartItems,
  handleSelectClub,
  handleSelectClubWithoutModal,
}) {
  return (
    <div className="main-wrap-clubSelect">
      {allClubs && allClubs.length > 1 ? (
        <div className="dropdown-info-text">
          Bytt til en annen tilgjengelig klubb:
        </div>
      ) : null}
      {allClubs && allClubs.length > 1 ? (
        <div className="downdown-club-container">
          <Select
            id="demo-simple-select-filled"
            value={selectedClub.id}
            onChange={
              cartItems.length > 0
                ? e => handleSelectClub(e)
                : e => handleSelectClubWithoutModal(e)
            }
            className="dropDown-select-club"
          >
            {allClubs && allClubs.length > 0
              ? allClubs.map(club => (
                  <MenuItem key={club.id} value={club.id}>
                    {`${club.name}`}
                  </MenuItem>
                ))
              : null}
          </Select>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = state => ({
  selectedClub: state.clubsReducer.selectedClub,
  allClubs: state.clubsReducer.allClubs,
  cartItems: state.shoppingCartReducer.cartItems,
});

export default connect(mapStateToProps)(ClubSelect);
