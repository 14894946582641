import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import './index.css';
import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchUserProfile, setIsAuthenticated } from 'actions/userAction';
import { updateAllClubs, updateSelectedClub } from 'actions/clubsActions';
import { Loading } from 'pages';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles';
import { API, checkAuthToken } from './util/Api';
import Router from './util/routing/Router';

function App({ selectedClub }) {
  const dispatch = useDispatch();
  const [isChecking, setIsChecking] = useState(true);

  const checkIfUserStillHasClub = (chosenClub, userClubs) => {
    const clubFound = userClubs.find(club => club.id === chosenClub.id);

    if (clubFound) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const checkToken = async () => {
      try {
        const { data } = await checkAuthToken(selectedClub.id);
        dispatch(fetchUserProfile(data.me));
        if (data.me.clubs) {
          dispatch(updateAllClubs(data.me.clubs));
        }
        if (selectedClub === null || Object.keys(selectedClub).length === 0) {
          dispatch(updateSelectedClub(data.me.clubs[0]));
        } else {
          const userStillHasSameClub = checkIfUserStillHasClub(
            selectedClub,
            data.me.clubs,
          );
          if (!userStillHasSameClub) {
            dispatch(updateSelectedClub(data.me.clubs[0]));
          }
        }
      } catch (error) {
        dispatch(setIsAuthenticated(false));
      } finally {
        setIsChecking(false);
      }
    };
    const authtoken = localStorage.getItem('authToken');
    if (authtoken) {
      API.defaults.headers.common.Authorization = `Bearer ${authtoken}`;
      checkToken();
    } else {
      setIsChecking(false);
    }
  }, [dispatch, selectedClub]);

  const theme = createTheme(
    adaptV4Theme({
      palette: {
        primary: {
          light: '#757ce8',
          main: '#11448F',
          dark: '#001e61',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000',
        },
      },
      overrides: {
        MuiButton: {
          root: {
            '&:disabled': {
              backgroundColor: 'rgb(216, 216, 216)',
            },
          },
        },
        MuiInputBase: {
          input: {
            '&:disabled': {
              backgroundColor: 'rgb(216, 216, 216)',
              borderRadius: '5px',
            },
          },
        },
        // to fix safari view:
        MuiOutlinedInput: {
          inputMarginDense: {
            paddingTop: '7.5px',
            paddingBottom: '8.5px',
            '&:disabled': {
              paddingTop: '7.5px',
              paddingBottom: '8.5px',
            },
          },
        },
      },
    }),
  );

  if (isChecking) {
    return <Loading />;
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Router />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const mapStateToProps = state => ({
  selectedClub: state.clubsReducer.selectedClub,
});

export default connect(mapStateToProps)(App);
