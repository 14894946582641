/* eslint-disable camelcase */
import React, { Component } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { shape, func, arrayOf } from 'prop-types';
import { getMyOrders } from '../../util/Api';
import { setUserOrders } from '../../actions/userAction';
import OrderItem from './OrderItem';

const propTypes = {
  setLoading: func.isRequired,
  dispatch: func.isRequired,
  userOrders: arrayOf(shape({})),
};

const defaultProps = {
  userOrders: [],
};

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getMyOrders();
  }

  componentDidUpdate(prevProps) {
    const { selectedClub } = this.props;
    if (prevProps.selectedClub !== selectedClub) {
      this.getMyOrders();
    }
  }

  getMyOrders = async () => {
    const { setLoading, dispatch, selectedClub } = this.props;
    const { data } = await getMyOrders(selectedClub.id);
    dispatch(setUserOrders(data));
    setLoading(true);
    try {
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  listOrders = () => {
    const { userOrders } = this.props;
    if (userOrders && userOrders.length > 0) {
      const sortedValues = userOrders.sort((a, b) => (a.id < b.id ? 1 : -1));
      return sortedValues.map((order, index) => {
        const lastItem = sortedValues.length === index + 1;
        const isItemColored = index % 2 === 0;
        // basket?.personalizations
        const personaliaztionsArr = order?.basket_items.reduce(
          (acc, basket) => acc.concat(basket?.personalizations),
          [],
        );
        const finteredNewItems = personaliaztionsArr.filter(
          item => item?.usesCollectedStructure === true,
        );
        const usesNewStructure = finteredNewItems?.length > 0;

        return (
          <OrderItem
            orderItem={order}
            key={order.id}
            lastItem={lastItem}
            isItemColored={isItemColored}
            usesNewStructure={usesNewStructure}
          />
        );
      });
    }
    return null;
  };

  renderContent = () => {
    const { userOrders } = this.props;
    if (userOrders && userOrders.length === 0) {
      return (
        <div>
          <div className="noOrdersMessage">Ingen ordrer funnet...</div>
        </div>
      );
    }

    return (
      <div>
        <div className="order-list-container">
          <div className="personalization-item">
            <div className="order-personalization-line-flex05" />
            <div className="order-personalization-line order-text-bold">ID</div>
            <div className="order-personalization-line order-text-bold">
              Ordredato
            </div>
            <div className="order-personalization-line order-text-bold">
              Status
            </div>
            <div className="order-personalization-line order-text-bold">
              Antall varer
            </div>
          </div>
          {this.listOrders()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="order-wrap">
        <div className="title-text-history">Ordrehistorikk</div>
        {this.renderContent()}
      </div>
    );
  }
}

OrderHistory.propTypes = propTypes;
OrderHistory.defaultProps = defaultProps;

const mapStateToProps = state => ({
  userOrders: state.userReducer.userOrders,
  selectedClub: state.clubsReducer.selectedClub,
});

export default connect(mapStateToProps)(OrderHistory);
