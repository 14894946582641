/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import './styles.scss';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import posed from 'react-pose';
import { connect } from 'react-redux';
import { updateSelectedClub } from 'actions/clubsActions';
import CheckIcon from 'mdi-react/CheckIcon';
import withUseNavigate from 'hooks/withUseNavigate';
import { logoutUser } from '../../actions/userAction';
import { resetBasket } from '../../actions/shoppingCartActions';
import ActionModal from '../shared/ActionModal';

const DropDown = posed.div({
  open: {
    height: 'auto',
  },

  closed: {
    height: 0,
  },
});

const ArrowIcon = posed.div({
  up: {
    rotate: '180deg',
  },
  down: {
    rotate: '0deg',
  },
});

class TopProfileButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showActionModal: false,
      changeToClub: null,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({ open: false });
  };

  handleSelectClubWithoutModal = club => {
    const { dispatch } = this.props;
    dispatch(updateSelectedClub(club));
    dispatch(resetBasket());
  };

  handleSelectClub = club => {
    this.setState({ changeToClub: club, showActionModal: true });
  };

  actionForModal = () => {
    const { dispatch } = this.props;
    const { changeToClub } = this.state;
    dispatch(updateSelectedClub(changeToClub));
    dispatch(resetBasket());
    this.setState({ showActionModal: false, changeToClub: null });
  };

  logoutUser = () => {
    const { dispatch } = this.props;
    localStorage.setItem('authToken', '');
    dispatch(logoutUser());
    dispatch(updateSelectedClub({}));
  };

  goToMypage = () => {
    const { navigate } = this.props;
    navigate('/profile');
  };

  toggleShowActionModal = () => {
    const { showActionModal } = this.state;
    this.setState({ showActionModal: !showActionModal });
  };

  render() {
    const { userProfile, selectedClub, allClubs, cartItems } = this.props;
    const { open, showActionModal } = this.state;

    if (
      userProfile &&
      userProfile.firstname &&
      userProfile.lastname &&
      selectedClub &&
      selectedClub.id
    ) {
      return (
        <div>
          <ActionModal
            toggleShowActionModal={this.toggleShowActionModal}
            showActionModal={showActionModal}
            modalText="Du har varer i handlekurven"
            explanationText="Du må tømme handlekurven først før du bytter klubben."
            actionButtonText="Slett varer"
            actionForModal={this.actionForModal}
            cancelButtonText="Lukk"
          />
          <button
            onClick={() => this.setState({ open: !open })}
            type="button"
            className="top-profile-button"
            ref={node => (this.node = node)}
          >
            <div className="profile-icon">
              <div className="profile-icon-letters">{`${userProfile.firstname[0]}${userProfile.lastname[0]}`}</div>
            </div>
            <div className="profile-icon-name-container">
              {`${userProfile.firstname} ${userProfile.lastname} (${selectedClub.name})`}
            </div>

            <ArrowIcon className="iconDiv-profile" pose={open ? 'up' : 'down'}>
              <ChevronDownIcon />
            </ArrowIcon>
          </button>
          <DropDown
            pose={open ? 'open' : 'closed'}
            className={open ? 'dropDown' : 'hideDropDown'}
          >
            <div className="profile-dropdown-container">
              {allClubs && allClubs.length > 1 ? (
                <div className="top-clubs-container">
                  {allClubs.map(club => (
                    <button
                      key={club.id}
                      className={
                        selectedClub.id !== club.id
                          ? 'profile-dropdown-item-notSelected'
                          : 'profile-dropdown-item-bold'
                      }
                      type="button"
                      onClick={
                        cartItems.length > 0
                          ? () => this.handleSelectClub(club)
                          : () => this.handleSelectClubWithoutModal(club)
                      }
                    >
                      {selectedClub.id === club.id ? (
                        <CheckIcon className="checkIcon" />
                      ) : null}
                      <div className="top-clubs-line">{`${club.name}`}</div>
                    </button>
                  ))}
                </div>
              ) : null}
              <button
                className="profile-dropdown-item-top"
                type="button"
                onClick={() => this.goToMypage()}
              >
                Min side
              </button>
              <button
                className="profile-dropdown-item-bottom"
                type="button"
                onClick={() => this.logoutUser()}
              >
                Logg ut
              </button>
            </div>
          </DropDown>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = state => ({
  userProfile: state.userReducer.userProfile,
  selectedClub: state.clubsReducer.selectedClub,
  allClubs: state.clubsReducer.allClubs,
  cartItems: state.shoppingCartReducer.cartItems,
});

const WrappedComponent = withUseNavigate(TopProfileButton);

export default connect(mapStateToProps)(WrappedComponent);
