import React, { Component } from 'react';
import './styles.scss';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import posed from 'react-pose';
import { shape, arrayOf, func } from 'prop-types';
import toggleProductListModal from '../../actions/productListActions';
import ContactInformationForm from './ContactInformationForm';
import { sendOrder, updateMyAddress } from '../../util/Api';
import {
  updateBasket,
  toggleConfirmationMessage,
} from '../../actions/shoppingCartActions';
import { updateUser } from '../../actions/userAction';
import { remapOrderData, isNumeric, isEmail } from '../../util/methods';

const DropDown = posed.div({
  open: {
    height: 'auto',
    overflow: 'auto',
    // display: 'flex',
  },

  closed: {
    height: 0,
    overflow: 'hidden',
    // display: 'none',
  },
});

const propTypes = {
  userProfile: shape({}),
  dispatch: func.isRequired,
  cartItems: arrayOf(shape({})),
  toggleLoadingState: func.isRequired,
  selectedClub: shape({}),
};

const defaultProps = {
  cartItems: [],
  userProfile: {},
  selectedClub: {},
};

class ContactInformationAndActions extends Component {
  constructor(props) {
    super(props);
    const { userProfile, selectedClub } = this.props;
    this.state = {
      contactInfoEditMode: false,
      hideCustomerData: true,
      errorsDel: [],
      errorsInv: [],

      delivery: {
        first_name:
          userProfile && userProfile.firstname ? userProfile.firstname : '',
        last_name:
          userProfile && userProfile.lastname ? userProfile.lastname : '',
        address:
          userProfile && userProfile.streetname ? userProfile.streetname : '',
        phone_number:
          userProfile && userProfile.username ? userProfile.username : '',
        email: userProfile && userProfile.email ? userProfile.email : '',
        team: selectedClub && selectedClub.name ? selectedClub.name : '',
        postal_code:
          userProfile && userProfile.postcode ? userProfile.postcode : '',
      },
      invoice: {
        first_name:
          userProfile && userProfile.firstname ? userProfile.firstname : '',
        last_name:
          userProfile && userProfile.lastname ? userProfile.lastname : '',
        address:
          userProfile && userProfile.streetname ? userProfile.streetname : '',
        postal_code:
          userProfile && userProfile.postcode ? userProfile.postcode : '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedClub } = this.props;
    const { delivery } = this.state;
    if (prevProps.selectedClub !== selectedClub) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        delivery: {
          ...delivery,
          team: selectedClub.name,
        },
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  checkFieldsForEmpty = stateV => {
    const fieldValues = Object.values(stateV);
    if (fieldValues.includes('')) {
      return true;
    }
    return false;
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(toggleProductListModal(false));
  };

  toggleHide = () => {
    const { hideCustomerData } = this.state;
    this.setState({ hideCustomerData: !hideCustomerData });
  };

  checkDeliveryForEmptyFields = () => {
    const { delivery } = this.state;
    return this.checkFieldsForEmpty(delivery);
  };

  checkInvoiceForEmptyFields = () => {
    const { invoice } = this.state;
    return this.checkFieldsForEmpty(invoice);
  };

  renderErrorIfHiddenData = () => {
    const { hideCustomerData } = this.state;
    if (
      hideCustomerData &&
      (this.checkDeliveryForEmptyFields() || this.checkInvoiceForEmptyFields())
    ) {
      return (
        <div className="contact-info-error">Mangler kundeinformasjon.</div>
      );
    }
    return null;
  };

  toggleEditMode = () => {
    const { contactInfoEditMode } = this.state;
    this.setState({
      contactInfoEditMode: !contactInfoEditMode,
    });
  };

  setError = (key, errorText, type) => {
    const { errorsInv, errorsDel } = this.state;
    if (type === 'delivery') {
      const filteredArr = errorsDel.filter(item => item.key !== key);
      this.setState({
        errorsDel: [...filteredArr, { errorText, key }],
      });
    } else if (type === 'invoice') {
      const filteredArr = errorsInv.filter(item => item.key !== key);
      this.setState({
        errorsInv: [...filteredArr, { errorText, key }],
      });
    }
  };

  changeFormValue = (type, key, value) => {
    let activeStateFields = {};
    const { delivery, invoice } = this.state;
    if (type === 'delivery') {
      activeStateFields = delivery;
    }
    if (type === 'invoice') {
      activeStateFields = invoice;
    }
    // Remove edited field from state and add it after in state object
    // also validate
    switch (key) {
      case 'first_name':
        delete activeStateFields.first_name;
        if (value.length < 3) {
          this.setError(key, 'Fornavn må ha minst 3 bokstaver.', type);
        } else {
          this.setError(key, '', type);
        }
        break;
      case 'last_name':
        delete activeStateFields.last_name;
        if (value.length < 3) {
          this.setError(key, 'Etternavn må ha minst 3 bokstaver.', type);
        } else {
          this.setError(key, '', type);
        }
        break;
      case 'address':
        delete activeStateFields.address;
        if (value.length < 3) {
          this.setError(key, 'Leveringsadresse er påkrevd.', type);
        } else {
          this.setError(key, '', type);
        }
        break;
      case 'phone_number':
        if (value.length !== 8) {
          this.setError(key, 'Telefonnumer må ha 8 sifre.', type);
        } else if (isNumeric(value) === false) {
          this.setError(key, 'Telefonnumer inneholde bare sifre.', type);
        } else {
          this.setError(key, '', type);
        }
        delete activeStateFields.phone_number;
        break;
      case 'email':
        if (!isEmail(value)) {
          this.setError(key, 'Epost adresse er påkrevd.', type);
        } else {
          this.setError(key, '', type);
        }
        delete activeStateFields.email;
        break;
      case 'team':
        if (value.length < 3) {
          this.setError(key, 'Lagnavn må ha minst 3 bokstaver.', type);
        } else {
          this.setError(key, '', type);
        }
        delete activeStateFields.team;
        break;
      case 'postal_code':
        if (value.length < 4 || value.length > 4) {
          this.setError(key, 'Postnummer må ha 4 sifre.', type);
        } else {
          this.setError(key, '', type);
        }
        delete activeStateFields.postal_code;
        break;
      default:
    }
    this.setState({
      [type]: {
        [key]: value,
        ...activeStateFields,
      },
    });
  };

  renderCustomerData = () => {
    const {
      // eslint-disable-next-line no-unused-vars
      hideCustomerData,
      delivery,
      invoice,
      errorsDel,
      errorsInv,
    } = this.state;
    /*    if (hideCustomerData) {
      return null;
    } */
    return (
      <div>
        <ContactInformationForm
          toggleEditMode={this.toggleEditMode}
          deliveryValues={delivery}
          invoiceValues={invoice}
          changeFormValue={this.changeFormValue}
          invoiceErrors={errorsInv}
          deliveryErrors={errorsDel}
        />
      </div>
    );
  };

  updateUserData = async changedUser => {
    const { userProfile, dispatch, selectedClub } = this.props;
    const { delivery } = this.state;

    await updateMyAddress(changedUser, selectedClub.id);

    // update redux with new state
    if (delivery) {
      const updatedUser = {
        ...userProfile,
        first_name: delivery.first_name,
        last_name: delivery.last_name,
        email: delivery.email,
        team: delivery.team,
        postcode: delivery.postal_code,
      };
      dispatch(updateUser(updatedUser));
    }
  };

  checkChangedAdressWhenOrdering = () => {
    const { delivery } = this.state;
    const { userProfile } = this.props;

    if (
      delivery.first_name !== userProfile.first_name ||
      delivery.last_name.name !== userProfile.last_name ||
      delivery.address !== userProfile.address ||
      delivery.email !== userProfile.email ||
      delivery.postal_code !== userProfile.postcode
    ) {
      const changedUser = {
        first_name: delivery.first_name,
        last_name: delivery.last_name,
        address: delivery.address,
        email: delivery.email,
        postcode: delivery.postal_code,
      };

      this.updateUserData(changedUser);
    }
  };

  sendOrderAction = async () => {
    const { dispatch, selectedClub } = this.props;
    const { cartItems, toggleLoadingState } = this.props;
    const { delivery, invoice } = this.state;

    toggleLoadingState(true);
    const mappedOrderData = remapOrderData(cartItems);
    this.checkChangedAdressWhenOrdering();
    try {
      await sendOrder(mappedOrderData, delivery, invoice, selectedClub.id).then(
        () => {
          dispatch(updateBasket([]));
          toast.success('Orden ble sendt!');
          toggleLoadingState(false);
          dispatch(toggleConfirmationMessage(true));
        },
      );
      dispatch(toggleProductListModal(false));
    } catch (err) {
      if (typeof err === 'string') {
        toast.error(err);
      } else {
        toast.error('Klarte ikke å sende ordren...');
      }
      toggleLoadingState(false);
    }
  };

  checkForValidationErrors = () => {
    const { errorsDel, errorsInv } = this.state;

    let errorsFound = false;
    if (errorsDel.length > 0) {
      errorsDel.forEach(error => {
        if (error.errorText !== '') {
          errorsFound = true;
        }
      });
    }
    if (errorsInv.length > 0) {
      errorsInv.forEach(error => {
        if (error.errorText !== '') {
          errorsFound = true;
        }
      });
    }

    return errorsFound;
  };

  checkIfViableForOrderSend = () => {
    const { cartItems } = this.props;
    if (
      this.checkInvoiceForEmptyFields() ||
      this.checkDeliveryForEmptyFields() ||
      cartItems.length === 0 ||
      this.checkForValidationErrors()
    ) {
      return true;
    }

    return false;
  };

  render() {
    const { hideCustomerData } = this.state;
    return (
      <div className="contact-info-wrapper">
        <div className="contact-info-name-and-button">
          <div className="contact-info-name-and-error-container ">
            <div className="contact-info-name">Kundeinformasjon</div>
          </div>
          <div>
            <button
              type="button"
              className="constact-info-button"
              onClick={() => this.toggleHide()}
            >
              {hideCustomerData ? 'VIS KUNDEINFORMASJON' : 'SKJUL'}
            </button>
          </div>
        </div>
        {this.renderErrorIfHiddenData()}
        <DropDown pose={hideCustomerData ? 'closed' : 'open'}>
          {this.renderCustomerData()}
        </DropDown>
        <div className="contact-info-buttom-container">
          <div className="contact-info-buttom-submit-container">
            <Button
              variant="contained"
              size="large"
              color="primary"
              className="contact-info-buttom-submit"
              onClick={() => this.sendOrderAction()}
              disabled={this.checkIfViableForOrderSend()}
            >
              SEND BESTILLING
            </Button>
          </div>
          <div className="contact-info-buttom-submit-container">
            <Button
              className="contact-info-buttom-cancel"
              size="large"
              color="primary"
              onClick={() => this.closeModal()}
              variant="outlined"
            >
              Lukk handlekurv
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ContactInformationAndActions.propTypes = propTypes;
ContactInformationAndActions.defaultProps = defaultProps;

const mapStateToProps = state => ({
  cartItems: state.shoppingCartReducer.cartItems,
  userProfile: state.userReducer.userProfile,
  selectedClub: state.clubsReducer.selectedClub,
});

export default connect(mapStateToProps)(ContactInformationAndActions);
