import React, { useState } from 'react';
import { connect } from 'react-redux';
import { shape, arrayOf } from 'prop-types';
import './styles.scss';
import { Button } from '@mui/material';
import ProductCard from '../ProductCard/ProductCard';
import IntersportLogo from '../../images/intersport-square.png';

function Product({ product, sizes }) {
  Product.propTypes = {
    product: shape({}),
    sizes: arrayOf(shape({})),
  };

  Product.defaultProps = {
    sizes: [],
    product: {},
  };

  const [expandPic, setExpandPic] = useState(false);

  const togglePicture = () => {
    setExpandPic(!expandPic);
  };

  function AvailableSizes() {
    if (sizes) {
      return sizes.map(size => {
        const stockCount = size.stock_count;
        const classes = `sizeCard${stockCount === 0 ? ' notInStock' : ''}`;
        return (
          <span key={size.size} className={classes}>
            {size.size}
          </span>
        );
      });
    }
    return null;
  }

  return (
    <div className="productLayout">
      {product && (
        <div className="product">
          <figure className="figure">
            <div className="productWrapping">
              <div className="imageDiv">
                <button
                  onClick={togglePicture}
                  type="button"
                  className="productImageContainer"
                >
                  {product?.images?.length > 0 ? (
                    <img
                      className="image"
                      src={`${
                        product?.images?.length > 0 ? product.images[0].url : ''
                      }300x300`}
                      alt="product_image"
                    />
                  ) : (
                    <img
                      className="imagePlaceholder"
                      src={IntersportLogo}
                      alt="product_image"
                    />
                  )}
                </button>
                <div className="titleAndBrandCointainer">
                  <div className="brand">
                    {product.brand ? product.brand.name : 'n/a'}
                  </div>
                  <div className="model">{product.name}</div>
                </div>
              </div>

              <figcaption className="infoContainer">
                <div className="stockContainer">
                  <div className="sizesContainer">
                    <AvailableSizes />
                  </div>
                </div>

                <div className="buyButton-container">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    onClick={togglePicture}
                    className="buyButton"
                  >
                    Vis vare
                  </Button>
                </div>
              </figcaption>
            </div>
            {expandPic ? (
              <div className="expandPicModal">
                <div className="expandPicModal-container">
                  <ProductCard
                    link={
                      product?.images[0]
                        ? `${product?.images[0]?.url}550x550`
                        : false
                    }
                    text={product}
                    sizes={sizes}
                    toggleModal={togglePicture}
                    clubImages={product?.club_product_image}
                  />
                </div>
              </div>
            ) : null}
          </figure>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Product);
