import React from 'react';
import { shape, arrayOf } from 'prop-types';

const listPersonalizations = (arr, orderIndex) => {
  if (arr.length > 0) {
    return arr.map(item => (
      <div
        key={orderIndex + item.id}
        className="order-personalization-line-container"
      >
        <div className="order-all-personalization-items-item">
          {item.name ? item.name : '-'}
        </div>
        <div className="order-all-personalization-items-item">
          {item.value ? item.value : '-'}
        </div>
      </div>
    ));
  }
  return null;
};

function PersonalizationItem({ personalizationItems, orderId = null }) {
  const renderOldStructure = item => (
    <div key={orderId + item.id} className="order-all-personalization-items">
      <div className="order-all-personalization-items-title-container">
        <div className="order-all-personalization-items-title-item">
          Personalisering
        </div>
        <div className="order-all-personalization-items-title-item">Verdi</div>
      </div>
      {listPersonalizations(item, orderId)}
    </div>
  );

  if (personalizationItems && personalizationItems.length > 0) {
    // check if any of items have new structure
    const foundFlagForNewStructure = personalizationItems.find(
      item => item?.usesCollectedStructure === true,
    );
    // new structure
    if (foundFlagForNewStructure !== undefined) {
      return (
        <div>
          <div className="order-new-personalization-container-title-container">
            <div className="order-new-personalization-container-title-item">
              Navn
            </div>
            <div className="order-all-personalization-items-title-item">
              Initialer
            </div>
            <div className="order-all-personalization-items-title-item">
              Nummer
            </div>
            <div className="order-all-personalization-items-title-item">
              Antall
            </div>
          </div>
          {personalizationItems.map((item, itemIndex) =>
            renderOldStructure(item, itemIndex),
          )}
        </div>
      );
    }
    // old structure
    return personalizationItems.map((item, itemIndex) =>
      renderOldStructure(item, itemIndex),
    );
  }
  return null;
}

PersonalizationItem.propTypes = {
  personalizationItems: arrayOf(shape({})),
};

PersonalizationItem.defaultProps = {
  personalizationItems: [],
};

export default PersonalizationItem;
