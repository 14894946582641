import {
  FETCH_PROFILE,
  UPDATE_PROFILE,
  UPDATE_ISAUTHENTICATED,
  LOGOUT_USER,
  SET_ORDERS,
} from './types';

export const fetchUserProfile = profile => ({
  type: FETCH_PROFILE,
  profile,
});

export const updateUser = profile => ({
  type: UPDATE_PROFILE,
  profile,
});

export const setIsAuthenticated = isAuthenticated => ({
  type: UPDATE_ISAUTHENTICATED,
  isAuthenticated,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const setUserOrders = orders => ({
  type: SET_ORDERS,
  orders,
});
