/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Navbar } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import posed from 'react-pose';
import CartIcon from 'mdi-react/ShoppingCartOutlineIcon';
import ActionModal from 'components/shared/ActionModal';
import { bool, shape } from 'prop-types';

import toggleProductListModal from '../../actions/productListActions';
import { resetBasket } from '../../actions/shoppingCartActions';
import TopProfileButton from './TopProfileButton';

const FilterAnimation = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 50,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 25 },
      default: { duration: 200 },
    },
  },
  exit: {
    y: 20,
    opacity: 0,
    transition: { duration: 50 },
  },
});

function Topbar({ cartItems, pagePosition, dispatch, cartObject }) {
  Topbar.propTypes = {
    pagePosition: bool,
    cartObject: shape({}),
  };

  Topbar.defaultProps = {
    pagePosition: false,
    cartObject: {},
  };

  const location = useLocation();
  const [isInMyPage, setIsInMyPage] = useState(false);

  useEffect(() => {
    if (location.pathname === '/profile') {
      setIsInMyPage(true);
    } else {
      setIsInMyPage(false);
    }
  }, [location]);

  const openCart = () => {
    dispatch(toggleProductListModal(true));
  };

  const handleResetCart = () => {
    dispatch(resetBasket());
  };

  return (
    <Navbar className="navbar">
      <ActionModal
        showActionModal={
          cartObject?.cartItems?.length > 0 &&
          !cartObject?.usingNewPersonalizationStructure
        }
        modalText="Vi har oppgradert personaliseringer"
        explanationText="Du får bedre oversikt over personaliseringer i handlekurven. For å benytte det, må du fjerne eksisterende handlekurv."
        actionButtonText="Fjern handlekurven"
        actionForModal={() => handleResetCart()}
        showCancelButton={false}
      />
      <div className="main-top">
        <div className="flexRow">
          <Link to="/" className="logoAndSaleContainer">
            <img
              src={require('../../images/intersport.png')}
              alt="logo"
              className="logo"
            />
            <div>
              <div className="saleTextHome">Klubbvareportal</div>
            </div>
          </Link>
          <div className="topCartAndProfileContainer">
            <TopProfileButton />
            <FilterAnimation
              pose={pagePosition || isInMyPage ? 'enter' : 'exit'}
            >
              <button
                className="cartButtonContainer-topBar"
                type="button"
                onClick={() => openCart()}
              >
                <CartIcon
                  className={
                    cartItems.length > 0
                      ? 'cartIconActive-topBar'
                      : 'cartIcon-topBar'
                  }
                />
                {cartItems.length > 0 ? (
                  <div className="itemsIncart-topbar" />
                ) : (
                  <div className="emptyCartNumber-topbar" />
                )}
              </button>
            </FilterAnimation>
          </div>
        </div>
      </div>
    </Navbar>
  );
}
const mapStateToProps = state => ({
  pagePosition: state.pagePositionReducer.pagePosition,
  mainMenuState: state.mainMenuReducer.mainMenuState,
  cartItems: state.shoppingCartReducer.cartItems,
  cartObject: state?.shoppingCartReducer,
});

export default connect(mapStateToProps)(Topbar);
