export function addToBasket(value) {
  return {
    type: 'ADD_TO_BASKET',
    value,
  };
}

export function removeFromBasket(value) {
  return {
    type: 'REMOVE_FROM_BASKET',
    value,
  };
}

export function updateBasket(value) {
  return {
    type: 'UPDATE_BASKET',
    value,
  };
}

export function resetBasket() {
  return {
    type: 'RESET_BASKET',
  };
}

export function changePersonalizationItem(
  cartItemId,
  personalizationId,
  personalizationItemId,
  personalizationType,
  value,
) {
  return {
    type: 'CHANGE_PERSONALIZATION_ITEM',
    personalizationType,
    value,
    cartItemId,
    personalizationItemId,
    personalizationId,
  };
}

export function resetPersonalizationItem(cartItemId, personalizationItemId) {
  return {
    type: 'RESET_PERSONALIZATION_ITEM',
    cartItemId,
    personalizationItemId,
  };
}

export function addPersonalizationItem(cartItemId, availablePersonalizations) {
  return {
    type: 'ADD_PERSONALIZATION_ITEM',
    cartItemId,
    availablePersonalizations,
  };
}

export function removePersonalizationItem(cartItemId, personalizationId) {
  return {
    type: 'REMOVE_PERSONALIZATION_ITEM',
    cartItemId,
    personalizationId,
  };
}

export function toggleConfirmationMessage(value) {
  return {
    type: 'SHOW_CONF_MESSAGE',
    value,
  };
}

export function addPersonalizationItemCollection(
  cartItemId,
  availablePersonalizations,
  quantity,
) {
  return {
    type: 'ADD_PERSONALIZATION_ITEMS_COLLECTION',
    cartItemId,
    availablePersonalizations,
    quantity,
  };
}

export function increaseProductCount(cartItemId) {
  return {
    type: 'INCREASE_PRODUCT_COUNT',
    cartItemId,
  };
}

export function decreaseProductCount(cartItemId) {
  return {
    type: 'DECREASE_PRODUCT_COUNT',
    cartItemId,
  };
}
export function adjustPersonalizationCount(
  cartItemId,
  personalizationItemId,
  actionType,
) {
  return {
    type: 'ADJUST_PERSONALIZATION_ITEM_QUANTITY',
    cartItemId,
    personalizationItemId,
    actionType,
  };
}

export function decreaseProductCountByNumber(cartItemId, amountToDecrease) {
  return {
    type: 'DECREASE_PRODUCT_COUNT_BY_NUMBER',
    cartItemId,
    amountToDecrease,
  };
}
