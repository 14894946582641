const initialState = {
  allBrands: [],
  selectedBrand: {},
};

export default function brandsReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_ALL_BRANDS':
      return {
        ...state,
        allBrands: action.value,
      };
    case 'UPDATE_SELECTED_BRAND':
      return {
        ...state,
        selectedBrand: action.value,
      };
    default:
      return state;
  }
}
