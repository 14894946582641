/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import './styles.scss';
import { shape, number, arrayOf, func } from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../Loader/Loader';
import { getClubStats } from '../../util/Api';
import ClubSelect from '../ClubSelect/ClubSelect';
import { updateSelectedClub } from '../../actions/clubsActions';

const propTypes = {
  selectedClub: shape({
    id: number,
  }).isRequired,
  allClubs: arrayOf(shape({})),
  dispatch: func.isRequired,
  setChangeToClub: func.isRequired,
  setShowActionModal: func.isRequired,
};

const defaultProps = { allClubs: [] };

function ClubStats({
  selectedClub,
  allClubs,
  dispatch,
  setChangeToClub,
  setShowActionModal,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [numClubUsers, setNumClubUsers] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [sumOfClubOrders, setSumOfClubOrders] = useState(0.0);

  const handleSelectClubWithoutModal = e => {
    const clubObj = allClubs.find(club => club.id === e.target.value);
    dispatch(updateSelectedClub(clubObj));
  };

  const handleSelectClub = e => {
    const clubObj = allClubs.find(clubMap => clubMap.id === e.target.value);
    setChangeToClub(clubObj);
    setShowActionModal(true);
  };

  const getStats = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getClubStats(selectedClub.id);
      setNumClubUsers(data.num_users_connected_to_club);
      setSumOfClubOrders(data.sum_payments.toFixed(2));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [selectedClub.id]);

  useEffect(() => {
    if (selectedClub) {
      getStats();
    }
  }, [getStats, selectedClub]);

  return (
    <div className="">
      <Loader state={loading} cover />
      <div className="order-wrap">
        <div className="stats-club-out-wrapper">
          <div className="club-stats-wrapper">
            {error && (
              <div className="club-stats-error">
                En feil oppstod under henting av statistikk. Last inn siden på
                nytt for å prøve igjen.
                <br />
                {`${error}`}
              </div>
            )}
            <div className="title-container-order">
              <div className="subtitle-text">
                {typeof selectedClub?.name === 'string'
                  ? `Klubbstatistikk: ${selectedClub?.name}`
                  : 'Klubbstatistikk'}
              </div>
            </div>
            <div className="club-stats-line">
              <div>Antall medlemmer tilknyttet klubben:</div>
              <div className="line-value">{`${numClubUsers}`}</div>
            </div>
            {/*           <div className="club-stats-line">
              <div>Sum av alle klubbordre:</div>
              <div className="line-value">{`${sumOfClubOrders} kr`}</div>
            </div> */}
          </div>
          <div className="stats-club-selector">
            {allClubs && allClubs.length > 1 ? (
              <ClubSelect
                handleSelectClubWithoutModal={handleSelectClubWithoutModal}
                handleSelectClub={handleSelectClub}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

ClubStats.propTypes = propTypes;
ClubStats.defaultProps = defaultProps;

const mapStateToProps = state => ({
  selectedClub: state.clubsReducer.selectedClub,
});

export default connect(mapStateToProps)(ClubStats);
