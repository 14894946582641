/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CartIcon from 'mdi-react/ShoppingCartOutlineIcon';
import { shape, arrayOf, func, number } from 'prop-types';
import Filters from '../Filters/Filters';
import './styles.scss';
import StoreSelector from '../StoreSelector/DropDown';
import toggleProductListModal from '../../actions/productListActions';
import { countProductsInCart } from '../../util/methods';

const propTypes = {
  products: arrayOf(shape({})),
  totalProductCount: number,
  dispatch: func.isRequired,
  cartItems: arrayOf(shape({})),
};

const defaultProps = {
  cartItems: [],
  products: [],
  totalProductCount: 0,
};

class ProductTop extends Component {
  openCart = () => {
    const { dispatch } = this.props;
    dispatch(toggleProductListModal(true));
  };

  render() {
    // If we do not have any category or products props, just return null.
    const { products, cartItems, totalProductCount } = this.props;
    if (!products) {
      return null;
    }

    return (
      products && (
        <>
          <div className="productTop">
            <div className="filtersAndCategory">
              <div className="spacingBetween" />
              <StoreSelector />
              <div className="cartContainer">
                <button
                  className="cartButtonContainer"
                  onClick={() => this.openCart()}
                  type="button"
                >
                  <CartIcon
                    className={
                      cartItems.length > 0 ? 'cartIconActive' : 'cartIcon'
                    }
                  />
                  {cartItems.length > 0 ? (
                    <div className="itemsIncart">
                      <div className="itemInCartNumber">
                        {countProductsInCart(cartItems)}
                      </div>
                    </div>
                  ) : (
                    <div className="emptyCartNumber" />
                  )}
                  <div
                    className={
                      cartItems.length > 0 ? 'cartNameActive' : 'cartName'
                    }
                  >
                    Handlekurv
                  </div>
                </button>
              </div>
            </div>
            {/*          <Filters
            className='filters'
            navBar={false}
          /> */}
          </div>
          {products.length !== 0 && totalProductCount ? (
            <div className="resultTopContainer">
              <p className="productCounter paddingCounter">
                Viser: {products.length} av {totalProductCount} produkter
              </p>
            </div>
          ) : null}
        </>
      )
    );
  }
}

ProductTop.propTypes = propTypes;
ProductTop.defaultProps = defaultProps;

const mapStateToProps = state => ({
  pagePosition: state.pagePositionReducer.pagePosition,
  totalProductCount: state.mainMenuReducer.totalProducts,
  productList: state.productListReducer.productList,
  cartItems: state.shoppingCartReducer.cartItems,
});

export default connect(mapStateToProps)(ProductTop);
