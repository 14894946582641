import React, { useState } from 'react';
import PlusIcon from 'mdi-react/PlusIcon';
import { connect } from 'react-redux';
import { shape, arrayOf, func, string } from 'prop-types';
import './styles.scss';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { toast } from 'react-toastify';
import posed from 'react-pose';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Button } from '@mui/material';
import AvailableSizes from './AvailableSizes';
import { updateBasket } from '../../actions/shoppingCartActions';
import { checkIfItemInCart } from '../../util/methods';
import IntersportLogo from '../../images/intersport-square.png';

const CornerCloseDiv = posed.div({
  hovering: {
    rotate: '90deg',
  },
  notHovering: {
    rotate: '0deg',
  },
});

function ProductCardModal({
  link,
  text,
  toggleModal,
  dispatch,
  cartItems,
  sizes,
  clubImages,
}) {
  const [hover, setHover] = useState(false);
  const [cart, setCart] = useState(cartItems);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const mouseEnter = () => {
    setHover(true);
  };

  const mouseLeave = () => {
    setHover(false);
  };

  const handleCurrentImageChange = index => {
    setCurrentIndex(index);
  };

  const renderCustomPager = imgArray => {
    if (imgArray?.length > 1) {
      return imgArray.map((img, index) => {
        if (index === currentImageIndex) {
          return <div className="fullCircleActive" key={img} />;
        }
        return <div className="fullCircle" key={img} />;
      });
    }
    return null;
  };

  const renderImage = linkUrl => {
    const imagesForRender = [];
    if (linkUrl) {
      imagesForRender[0] = linkUrl;
    }
    if (clubImages?.length > 0) {
      imagesForRender[1] = clubImages[0]?.url;
    }

    if (imagesForRender?.length > 1) {
      return (
        <div style={{ width: '100%' }}>
          <div className="carouselContainer">
            <Carousel
              dynamicHeight
              axis="horizontal"
              showThumbs={false}
              showStatus={false}
              autoPlay={false}
              swipeable
              showIndicators={false}
              emulateTouch
              interval={9999999999}
              onChange={e => handleCurrentImageChange(e)}
              renderArrowPrev={(onClickHandler, hasPrev, label) => {
                if (hasPrev) {
                  return (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      className="arrowStyleLButton"
                    >
                      <ChevronDownIcon className="arrowStyleL" />
                    </button>
                  );
                }
                return (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="arrowStyleLButtonDeactivated"
                  >
                    <ChevronDownIcon className="arrowStyleL" />
                  </button>
                );
              }}
              renderArrowNext={(onClickHandler, hasNext, label) => {
                if (hasNext) {
                  return (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      className="arrowStyleRButton"
                    >
                      <ChevronDownIcon className="arrowStyleR" />
                    </button>
                  );
                }
                return (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="arrowStyleRButtonDeactivated"
                  >
                    <ChevronDownIcon className="arrowStyleR" />
                  </button>
                );
              }}
            >
              {imagesForRender.map(item => (
                <img
                  key={item}
                  src={item}
                  alt="club_image"
                  className="expandPic"
                />
              ))}
            </Carousel>
            <div className="customPagerContainer">
              {renderCustomPager(imagesForRender)}
            </div>
          </div>
        </div>
      );
    }
    if (linkUrl) {
      return <img src={linkUrl} className="expandPic" alt="product_image" />;
    }
    return (
      <img
        src={IntersportLogo}
        className="expandPicPlaceholder"
        alt="product_image"
      />
    );
  };

  const addToCart = () => {
    dispatch(updateBasket(cart));
    toast.success(
      checkIfItemInCart(cartItems, sizes)
        ? 'Handlekurven oppdatert!'
        : 'Varer lagt til handlekurven!',
    );
    toggleModal();
  };
  /* eslint-disable react/no-array-index-key */
  const renderUsps = uspsArray =>
    uspsArray.map((item, i) => {
      if (item) {
        return (
          <div className="uspsItemContainer" key={i}>
            <div className="uspsItemText">-</div>
            <div className="uspsItemText">{item}</div>
          </div>
        );
      }
      return null;
    });
  /* eslint-enable react/no-array-index-key */

  return (
    <div className="expandCard">
      <div className="expandCardHalf expandCardHalfImage noPaddingImage">
        {renderImage(link)}
      </div>
      <div className="dividerLine" />
      <div className="expandCardHalf">
        <div className="closeIconContainer">
          <CornerCloseDiv
            pose={hover ? 'hovering' : 'notHovering'}
            id="productListModal-cornerButton"
            onClick={() => toggleModal()}
            className="closeModal"
          >
            <PlusIcon id="productListModal-cornerIcon" className="closeIcon" />
            <div
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
              id="productListModal-corner"
              className="iconFiller"
            />
          </CornerCloseDiv>
        </div>
        <div className="expandCard-TextContainer">
          <div className="expandCard-brandName">{text.brand.name}</div>
          <div className="expandCard-modelName">{text.name}</div>
          <div className="expandCard-modelSKU">
            {text.color_sku ? `SKU: ${text.color_sku}` : null}
          </div>
          {text?.usps?.length > 0 ? (
            <div className="uspsContainer">{renderUsps(text.usps)}</div>
          ) : null}
          <div className="expandCard-descriptionName">
            {text.description ? 'PRODUKTBESKRIVELSE' : null}
          </div>
          {text && text.description ? (
            <div className="expandCard-description">
              {text.description.replace(/<[^>]+>/g, '')}
            </div>
          ) : null}
          <div className="expandCard-SizeAndButtonsContainer">
            <div className="expandCard-sizeName">Størrelser:</div>
            <div className="expandCard-sizeContainer">
              <AvailableSizes
                cart={cart}
                sizes={sizes}
                setCart={setCart}
                text={text}
              />
            </div>
            <div className="productCardButtonContainer">
              <Button
                onClick={() => addToCart()}
                variant="contained"
                size="large"
                color="primary"
                className="addToBasketButton"
                // disabled={cart.length === 0}
              >
                {checkIfItemInCart(cartItems, sizes)
                  ? 'Oppdater handlekurven'
                  : 'Legg til handlekurv'}
              </Button>
              <div className="mobile">
                <Button
                  type="button"
                  className="productCardModal-close"
                  onClick={() => toggleModal()}
                  size="large"
                  color="primary"
                  variant="outlined"
                >
                  Lukk
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  cartItems: state.shoppingCartReducer.cartItems,
});

ProductCardModal.propTypes = {
  text: shape({}),
  link: string,
  toggleModal: func.isRequired,
  dispatch: func.isRequired,
  cartItems: arrayOf(shape({})).isRequired,
  sizes: arrayOf(shape({})),
  clubImages: arrayOf(shape({})),
};

ProductCardModal.defaultProps = {
  text: {},
  link: '',
  sizes: [],
  clubImages: [],
};

export default connect(mapStateToProps)(ProductCardModal);
