/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
const initialState = {
  cartItems: [],
  showConfirmationMessage: false,
  // this is used to force users to delete carts with old personalization structure
  usingNewPersonalizationStructure: false,
};

export default function shoppingCartReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADD_TO_BASKET':
      const newCart = state.cartItems.concat(action.value);
      return {
        ...state,
        usingNewPersonalizationStructure: true,
        cartItems: newCart,
      };
    case 'UPDATE_BASKET':
      return {
        ...state,
        cartItems: action.value,
      };
    case 'REMOVE_FROM_BASKET':
      return {
        ...state,
        cartItems: action.value,
      };
    case 'RESET_BASKET':
      return {
        ...state,
        usingNewPersonalizationStructure: true,
        cartItems: [],
      };
    case 'CHANGE_PERSONALIZATION_ITEM':
      // eslint-disable-next-line no-case-declarations
      const mappedData = state.cartItems.map(cartItem => {
        if (
          cartItem.id === action.cartItemId &&
          cartItem.activePersonalizations.length > 0
        ) {
          const changedPersonalizations = cartItem.activePersonalizations.map(
            persinalizationItem => {
              if (persinalizationItem.id === action.personalizationItemId) {
                const items = persinalizationItem.items.map(item => {
                  if (
                    item.id === action.personalizationId &&
                    item.name === action.personalizationType
                  ) {
                    return {
                      ...item,
                      id: item.id,
                      name: item.name,
                      value: action.value,
                    };
                  }
                  return item;
                });
                return {
                  ...persinalizationItem,
                  items,
                  id: persinalizationItem.id,
                };
              }
              return persinalizationItem;
            },
          );

          return {
            ...cartItem,
            activePersonalizations: changedPersonalizations,
          };
        }

        return cartItem;
      });
      return {
        ...state,
        cartItems: mappedData,
      };

    case 'RESET_PERSONALIZATION_ITEM':
      const resetedCart = state.cartItems.map(cartItem => {
        if (
          cartItem.id === action.cartItemId &&
          cartItem.activePersonalizations.length > 0
        ) {
          const changedPersonalizations = cartItem.activePersonalizations.map(
            persinalizationItem => {
              if (persinalizationItem.id === action.personalizationItemId) {
                const items = persinalizationItem.items.map(item => ({
                  id: item.id,
                  name: item.name,
                  value: '',
                }));
                return {
                  items,
                  id: persinalizationItem.id,
                };
              }

              return persinalizationItem;
            },
          );
          return {
            ...cartItem,
            activePersonalizations: changedPersonalizations,
          };
        }

        return cartItem;
      });
      return {
        ...state,
        cartItems: resetedCart,
      };

    case 'ADD_PERSONALIZATION_ITEM':
      const newCartItems = state.cartItems.map(cartItem => {
        if (
          cartItem.id === action.cartItemId &&
          action.availablePersonalizations.length > 0
        ) {
          const availablePerArr = [];
          action.availablePersonalizations.forEach(pers => {
            const personalItem = {};
            personalItem.id = Math.random();
            personalItem.name = pers;
            personalItem.value = '';

            availablePerArr.push(personalItem);
          });
          const newItem = {};
          newItem.id = Math.random();
          newItem.items = availablePerArr;
          const changedPersonalizations =
            cartItem.activePersonalizations.concat(newItem);
          return {
            ...cartItem,
            activePersonalizations: changedPersonalizations,
            quantity: parseInt(cartItem.quantity, 10) + 1,
          };
        }

        return cartItem;
      });
      return {
        ...state,
        cartItems: newCartItems,
      };

    case 'REMOVE_PERSONALIZATION_ITEM':
      const trimmedCartItems = state.cartItems.map(cartItem => {
        if (cartItem.id === action.cartItemId) {
          const changedPersonalizations =
            cartItem.activePersonalizations.filter(
              pers => pers.id !== action.personalizationId,
            );
          return {
            ...cartItem,
            activePersonalizations: changedPersonalizations,
          };
        }

        return cartItem;
      });
      return {
        ...state,
        cartItems: trimmedCartItems,
      };
    case 'SHOW_CONF_MESSAGE':
      return {
        ...state,
        showConfirmationMessage: action.value,
      };
    case 'ADD_PERSONALIZATION_ITEMS_COLLECTION':
      const remappedCartItems = state.cartItems.map(cartItem => {
        if (
          cartItem.id === action.cartItemId &&
          action.availablePersonalizations.length > 0 &&
          action.quantity > 0
        ) {
          const availablePerArr = [];
          action.availablePersonalizations.forEach(pers => {
            const personalItem = {};
            personalItem.id = Math.random();
            personalItem.name = pers;
            personalItem.value = '';

            availablePerArr.push(personalItem);
          });
          const newItem = {};
          newItem.id = Math.random();
          newItem.items = availablePerArr;
          newItem.quantity = action.quantity;
          newItem.usesCollectedStructure = true;
          const changedPersonalizations =
            cartItem.activePersonalizations.concat(newItem);
          return {
            ...cartItem,
            activePersonalizations: changedPersonalizations,
            quantity: parseInt(cartItem.quantity, 10),
          };
        }

        return cartItem;
      });
      return {
        ...state,
        cartItems: remappedCartItems,
      };

    case 'INCREASE_PRODUCT_COUNT':
      const updatedCartItems = state.cartItems.map(cartItem => {
        if (cartItem.id === action.cartItemId) {
          return {
            ...cartItem,
            quantity: parseInt(cartItem.quantity, 10) + 1,
          };
        }

        return cartItem;
      });
      return {
        ...state,
        cartItems: updatedCartItems,
      };

    case 'DECREASE_PRODUCT_COUNT':
      const updatedCartItemsReduced = state.cartItems.map(cartItem => {
        if (cartItem.id === action.cartItemId) {
          return {
            ...cartItem,
            quantity: parseInt(cartItem.quantity, 10) - 1,
          };
        }

        return cartItem;
      });
      return {
        ...state,
        cartItems: updatedCartItemsReduced,
      };
    case 'ADJUST_PERSONALIZATION_ITEM_QUANTITY':
      const dataForDecreasePersonalizations = state.cartItems.map(cartItem => {
        if (
          cartItem.id === action.cartItemId &&
          cartItem.activePersonalizations.length > 0
        ) {
          const changedPersonalizations = cartItem.activePersonalizations.map(
            persinalizationItem => {
              if (persinalizationItem.id === action.personalizationItemId) {
                if (action.actionType === 'increase') {
                  return {
                    ...persinalizationItem,
                    quantity: persinalizationItem.quantity + 1,
                  };
                }
                if (
                  action.actionType === 'decrease' &&
                  persinalizationItem?.quantity > 1
                ) {
                  return {
                    ...persinalizationItem,
                    quantity: persinalizationItem.quantity - 1,
                  };
                }
              }
              return persinalizationItem;
            },
          );

          return {
            ...cartItem,
            activePersonalizations: changedPersonalizations,
          };
        }

        return cartItem;
      });
      return {
        ...state,
        cartItems: dataForDecreasePersonalizations,
      };

    case 'DECREASE_PRODUCT_COUNT_BY_NUMBER':
      const updatedCartItemsReducedByNumber = state.cartItems.map(cartItem => {
        if (cartItem.id === action.cartItemId) {
          const oldQuantity = parseInt(cartItem.quantity, 10);
          const newQuantity = oldQuantity - action.amountToDecrease;
          return {
            ...cartItem,
            quantity: newQuantity,
          };
        }

        return cartItem;
      });
      return {
        ...state,
        cartItems: updatedCartItemsReducedByNumber,
      };
    default:
      return state;
  }
}
