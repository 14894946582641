/* eslint-disable indent */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import posed from 'react-pose';
import { useNavigate } from 'react-router-dom';
import { bool, func } from 'prop-types';
import { toggleConfirmationMessage } from '../../actions/shoppingCartActions';
import toggleProductListModal from '../../actions/productListActions';

const Shade = posed.div({
  enter: {
    opacity: 1,
    overflow: 'visible',
    top: '75px',
    height: 'auto',
    transition: {
      height: { duration: 0 },
      top: { duration: 0 },
    },
    backgroundColor: 'rgba(255,255,255,.9)',
  },
  exit: {
    opacity: 0,
    overflow: 'hidden',
    top: '-800px',
    height: '0',
    transition: {
      height: { duration: 0, delay: 150 },
      top: { duration: 0, delay: 150 },
    },
    backgroundColor: 'rgba(255,255,255,0)',
  },
});

function OrderSentMessage({ showConfirmationMessage, dispatch }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (showConfirmationMessage === true) {
      setInterval(() => dispatch(toggleConfirmationMessage(false)), 5000);
    }
  }, [dispatch, showConfirmationMessage]);

  const goToMyPage = () => {
    navigate('/profile');
    dispatch(toggleProductListModal(false));
  };

  return (
    <Shade className="OkMessage-container">
      {showConfirmationMessage ? (
        <div className="OkMessage-text">
          Ordren ble sendt! Du kan se oversikt og status på&nbsp;
          <button
            type="button"
            onClick={() => goToMyPage()}
            className="okMessage-button"
          >
            Min Side.
          </button>
        </div>
      ) : null}
    </Shade>
  );
}
const mapStateToProps = state => ({
  showConfirmationMessage: state.shoppingCartReducer.showConfirmationMessage,
});

OrderSentMessage.propTypes = {
  dispatch: func.isRequired,
  showConfirmationMessage: bool,
};
OrderSentMessage.defaultProps = {
  showConfirmationMessage: false,
};

export default connect(mapStateToProps)(OrderSentMessage);
