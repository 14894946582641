/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, arrayOf, func } from 'prop-types';
import './styles.scss';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import posed from 'react-pose';
import CheckIcon from 'mdi-react/CheckIcon';
import { updateSelectedBrand } from '../../actions/brandsActions';

const propTypes = {
  selectedBrand: shape({}),
  allBrands: arrayOf(shape({})),
};

const defaultProps = {
  selectedBrand: {},
  allBrands: [],
};

const DropDownItem = posed.div({
  hovering: {
    backgroundColor: '#e1e1e1',
    fontWeight: 300,
  },
  notHovering: {
    backgroundColor: '#ffffff',
    fontWeight: 300,
  },
});

const DropDown = posed.div({
  open: {
    height: 'auto',
  },

  closed: {
    height: 0,
  },
});

const ArrowIcon = posed.div({
  up: {
    rotate: '180deg',
  },
  down: {
    rotate: '0deg',
  },
});

class StoreSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({ open: false });
  };

  render() {
    const { selectedBrand, allBrands } = this.props;
    const { open } = this.state;
    return (
      <div
        className="filter"
        ref={node => (this.node = node)}
        id="scrollToTop"
      />
    );
  }
}

StoreSelector.propTypes = propTypes;
StoreSelector.defaultProps = defaultProps;

const mapStateToProps = state => ({
  pagePosition: state.pagePositionReducer.pagePosition,
  allBrands: state.brandsReducer.allBrands,
  selectedBrand: state.brandsReducer.selectedBrand,
});

export default connect(mapStateToProps)(StoreSelector);
