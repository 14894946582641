import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const withUseNavigate = Component =>
  // eslint-disable-next-line func-names
  function ({ ...props }) {
    const params = useParams();
    const navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  };

export default withUseNavigate;
