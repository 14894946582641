/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */
const countProductsInCart = cartItems => {
  let count = 0;
  cartItems.forEach(product => {
    count += parseInt(product.quantity, 10);
  });

  return count;
};

const countQuantityPerOrderItem = orderItem => {
  if (orderItem && orderItem.basket_items.length > 0) {
    let totalAmount = 0;
    orderItem.basket_items.forEach(item => {
      if (item.count) {
        totalAmount += parseInt(item.count, 10);
      } else if (item.quantity) {
        totalAmount += parseInt(item.quantity, 10);
      }
    });
    return totalAmount;
  }
  return null;
};

const checkIfSizeInCart = (cart, size) => {
  let isInCart = false;
  if (cart && cart.length > 0) {
    cart.forEach(item => {
      const parsedQuantity = parseInt(item.quantity, 10);
      if (
        item.size.sku === size.sku &&
        typeof parsedQuantity === 'number' &&
        parsedQuantity > 0
      ) {
        isInCart = true;
      }
    });
  }
  return isInCart;
};

const checkIfItemInCart = (cart, sizes) => {
  let isInCart = false;
  if (cart && cart.length > 0) {
    cart.forEach(item => {
      const filtered = sizes.filter(size => size.sku === item.size.sku);
      if (filtered.length > 0) {
        isInCart = true;
      }
    });
  }
  return isInCart;
};

const checkifItemHasNoPersonalizations = (
  cartItems,
  itemId,
  personalizationId,
) => {
  const itemForSearch = cartItems.find(cartItem => cartItem.id === itemId);
  let foundItem = false;
  if (itemForSearch && itemForSearch.availablePersonalizations.length > 0) {
    itemForSearch.activePersonalizations.forEach(personalization => {
      if (personalization.id === personalizationId) {
        if (personalization.items.length > 0) {
          let amountOfEmpty = 0;
          personalization.items.forEach(persType => {
            if (persType.value === '') {
              // eslint-disable-next-line no-plusplus
              amountOfEmpty++;
            }

            if (amountOfEmpty === 3) {
              foundItem = true;
            }
          });
        }
      }
    });
  }
  return foundItem;
};

const remapOrderData = cart => {
  if (cart.length > 0) {
    const remappedData = cart.map(item => ({
      size: item.size.size,
      id: item.id,
      name: item.text.name,
      ean: item.size.ean,
      sku: item.size.sku,
      count: item.quantity,
      personalizations: item.activePersonalizations,
    }));

    return remappedData;
  }
  return null;
};

const isNumeric = value => /^-?\d+$/.test(value);

const isEmail = value => {
  const re =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(value);
};

export {
  countProductsInCart,
  checkIfSizeInCart,
  checkIfItemInCart,
  checkifItemHasNoPersonalizations,
  remapOrderData,
  countQuantityPerOrderItem,
  isNumeric,
  isEmail,
};
