import React from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import FilterCat from './FilterCat';

function Filters() {
  return (
    <div className="filter meniuLayout">
      <FilterCat />
    </div>
  );
}

const mapStateToProps = state => ({
  pagePosition: state.pagePositionReducer.pagePosition,
  sortByPrice: state.sortByPriceReducer.price,
});

export default connect(mapStateToProps)(Filters);
