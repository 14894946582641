import React from 'react';
import './styles.scss';

function Loader({ cover, state, brightness }) {
  if (cover && state) {
    return (
      <div className="loader-cover">
        <div className="loading">
          <div className="lds-roller">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  }
  if (state) {
    return (
      <div className="loading">
        <div
          style={{ filter: `brightness(${brightness})` }}
          className="lds-roller"
        >
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
  return null;
}

Loader.defualtProps = {
  cover: false,
  brightness: 1,
};

export default Loader;
