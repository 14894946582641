import {
  FETCH_PROFILE,
  UPDATE_PROFILE,
  UPDATE_ISAUTHENTICATED,
  LOGOUT_USER,
  SET_ORDERS,
} from 'actions/types';

const initialState = {
  userProfile: null,
  isAuthenticated: false,
  userOrders: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        userProfile: action.profile,
      };
    case FETCH_PROFILE:
      return {
        ...state,
        userProfile: action.profile,
        isAuthenticated: true,
      };
    case UPDATE_ISAUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        userProfile: null,
      };

    case SET_ORDERS:
      return {
        ...state,
        userOrders: action.orders,
      };

    default:
      return state;
  }
}
