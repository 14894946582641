import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import { shape, func } from 'prop-types';
import { updateTotalProductCount } from '../../actions/mainMenuActions';
import ProductTop from '../../components/ProductTop/ProductTop';
import Product from '../../components/Product/Product';
import './styles.scss';
import { getProductsPaged } from '../../util/Api';
import Loader from '../../components/Loader/Loader';
import { updatePagePosition } from '../../actions/pagePositionActions';

const propTypes = {
  selectedBrand: shape({}),
  dispatch: func.isRequired,
};

const defaultProps = {
  selectedBrand: {},
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      products: [],
      page: 1,
      totalPages: null,
    };
  }

  componentDidMount() {
    const { selectedClub } = this.props;
    if (selectedClub && selectedClub.id) {
      this.getProductsPaged(1);
      this.setState({ page: 1 });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, loading } = this.state;
    const { selectedClub } = this.props;

    if (prevProps.selectedClub && prevProps.selectedClub !== selectedClub) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ products: [], page: 1, totalPages: null }, () => {
        this.getProductsPaged(1);
      });
    } else if (
      prevState.page !== page &&
      loading === false &&
      prevProps.selectedClub.id === selectedClub.id &&
      page > 1
    ) {
      // GET products when page changes
      this.getProductsPaged(page);
    }
  }

  checkScrollHeight = () => {
    const { totalPages, page } = this.state;
    const productElement = document.getElementById('products');
    if (window.innerHeight > productElement.scrollHeight && totalPages > page) {
      this.setState({ page: page + 1 });
    }
  };

  checkScrollHeightInitial = () => {
    const { selectedBrand } = this.props;
    const { page } = this.state;
    const productElement = document.getElementById('products');
    if (
      window.innerHeight > productElement.scrollHeight &&
      selectedBrand.id !== 0
    ) {
      this.setState({ page: page + 1 });
    }
  };

  getProductsPaged = async currentPage => {
    const { dispatch, selectedClub } = this.props;
    const { page, products } = this.state;
    this.setState({ loading: true });
    try {
      if (selectedClub === null || Object.keys(selectedClub).length === 0) {
        toast.error('Du har ingen klubb knyttet mot din bruker.');
        return;
      }
      const { data } = await getProductsPaged(currentPage, selectedClub.id);
      // other pages:
      if (page !== 1 && data && data.length !== 0) {
        const newProducts = [...products].concat(data.data);
        this.setState({
          products: newProducts,
          loading: false,
          totalPages: data.last_page,
        });
        this.checkScrollHeight();
        return;
      }
      // page 1
      if (page === 1 && data && data.length !== 0) {
        this.setState({
          products: data.data,
          loading: false,
          totalPages: data.last_page,
        });
        dispatch(updateTotalProductCount(data.total));
        this.checkScrollHeightInitial();
      }
    } catch (err) {
      toast.error('Klarte ikke å hente produkter...');
      this.setState({ loading: false });
    }
  };

  renderProducts = () => {
    const { products } = this.state;

    if (products && products.length > 0) {
      return products.map(product => (
        <Product
          key={`product${product.color_sku}`}
          token="foobar"
          product={product}
          sizes={product.sizes}
          stock={product.stock_count}
          lastUpdate={product.stock_fetched_at}
          totalStockCount={product.total_stock_count}
        />
      ));
    }
    return (
      <div className="noProductsText">
        Ingen varer funnet knyttet til denne klubben...
      </div>
    );
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  updateFilterPosition = event => {
    const { pagePosition, dispatch } = this.props;

    // To know if top shoppingIcon on top should be shown:
    let pagePositionLoc;
    if (event.target.scrollTop > 70) {
      pagePositionLoc = true;
    } else {
      pagePositionLoc = false;
    }
    if (pagePosition !== pagePositionLoc) {
      dispatch(updatePagePosition(pagePositionLoc));
    }

    // To load more products on scroll:
    /*     const { page, totalPages, loading } = this.state;
    if (event.target.scrollHeight - event.target.scrollTop - window.innerHeight + 50 === 0
      && page < totalPages && !loading) {
      this.setState({ page: page + 1 });
    } */
  };

  addPage = () => {
    const { page } = this.state;
    this.setState({ page: page + 1 });
  };

  render() {
    const { products, loading, page, totalPages } = this.state;
    return (
      <div
        className="home"
        id="home"
        ref={this.parentNode}
        onScroll={this.updateFilterPosition}
      >
        <div className="main-center">
          <div className="main" onScroll={this.updateFilterPosition}>
            <ProductTop
              products={products}
              setSortByPrice={this.setSortByPrice}
            />
            <div className="productWrap" id="products">
              {this.renderProducts()}
              <div className="filler">
                {totalPages && totalPages > page ? (
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    onClick={() => this.addPage()}
                  >
                    Se flere varer
                  </Button>
                ) : null}
                <Loader state={loading} cover />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

const mapStateToProps = state => ({
  sortByPrice: state.sortByPriceReducer.price,
  allBrands: state.brandsReducer.allBrands,
  selectedBrand: state.brandsReducer.selectedBrand,
  selectedCategory: state.categoriesReducer.selectedCategory,
  pagePosition: state.pagePositionReducer.pagePosition,
  selectedClub: state.clubsReducer.selectedClub,
});

export default connect(mapStateToProps)(Home);
