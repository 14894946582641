import React, { useState } from 'react';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Profile from '../../components/Profile/Profile';
import OrderHistory from '../../components/OrderHistory/OrderHistory';
import Loader from '../../components/Loader/Loader';
import './styles.scss';
import { resetBasket } from '../../actions/shoppingCartActions';
import ActionModal from '../../components/shared/ActionModal';
import { updateSelectedClub } from '../../actions/clubsActions';
import ClubStats from '../../components/ClubStats/ClubStats';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    paddingLeft: '4px',
    paddingRight: '4px',
    textTransform: 'none',
    fontWeight: 'Bold',
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
    fontFamily: ['DINPro'],
  },
}))(props => <Tab disableRipple {...props} />);

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className="tabpanel-container"
      {...other}
    >
      {value === index && <div className="tabpanel-wrapper">{children}</div>}
    </div>
  );
}

function MyPage({ allClubs, dispatch }) {
  const [loading, setLoading] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [changeToClub, setChangeToClub] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);

  const classes = useStyles();

  const actionForModal = () => {
    dispatch(updateSelectedClub(changeToClub));
    dispatch(resetBasket());
    setShowActionModal(false);
    setChangeToClub(null);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className="mainWrap">
      <Loader state={loading} cover />
      <ActionModal
        toggleShowActionModal={() => setShowActionModal(!showActionModal)}
        showActionModal={showActionModal}
        modalText="Du har varer i handlekurven"
        explanationText="Du må tømme handlekurven først før du bytter klubben."
        actionButtonText="Slett varer"
        actionForModal={actionForModal}
        cancelButtonText="Lukk"
      />
      <div className="title-container">
        <div className="title-text">Min side</div>
      </div>
      <div className={classes.root}>
        <StyledTabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="meny tabs"
        >
          <StyledTab label="Ordrehistorikk" {...a11yProps(0)} />
          <StyledTab label="Personlig data" {...a11yProps(1)} />
        </StyledTabs>
      </div>
      <TabPanel value={currentTab} index={0}>
        <ClubStats
          allClubs={allClubs}
          setChangeToClub={setChangeToClub}
          setShowActionModal={setShowActionModal}
        />
        <OrderHistory setLoading={setLoading} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Profile setLoading={setLoading} dispatch={dispatch} />
      </TabPanel>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedClub: state.clubsReducer.selectedClub,
  allClubs: state.clubsReducer.allClubs,
});

export default connect(mapStateToProps)(MyPage);
