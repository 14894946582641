/* eslint-disable import/prefer-default-export */
export function updateAllClubs(value) {
  return {
    type: 'UPDATE_ALL_CLUBS',
    value,
  };
}

export function updateSelectedClub(value) {
  return {
    type: 'UPDATE_SELECTED_CLUB',
    value,
  };
}
