import React, { useState } from 'react';
import { shape, bool } from 'prop-types';
import posed from 'react-pose';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { countQuantityPerOrderItem } from '../../util/methods';
import PersonalizationItem from './PersonalizationItem';

const findPersonalizationItemValue = (persArr, persName) => {
  if (persArr?.length === 0 || persArr === undefined) {
    return '-';
  }
  const persItem = persArr.find(item => item.name === persName);
  if (persItem !== undefined && persItem?.value !== null) {
    return persItem?.value;
  }
  return '-';
};

const findPersonalizationNumberValue = persArr => {
  if (persArr?.length === 0 || persArr === undefined) {
    return '-';
  }
  const persItem = persArr.find(item => item.name === 'Nummer');
  const persItemContainsIntervalFrom = persArr.find(
    item => item.name === 'Nummer fra',
  );
  const persItemContainsIntervalTo = persArr.find(
    item => item.name === 'Nummer til',
  );
  if (persItem !== undefined && persItem?.value !== null) {
    return persItem?.value;
  }
  if (
    persItemContainsIntervalFrom !== undefined &&
    persItemContainsIntervalFrom?.value !== null &&
    persItemContainsIntervalTo?.value !== null
  ) {
    return `${persItemContainsIntervalFrom?.value} - ${persItemContainsIntervalTo?.value}`;
  }
  return '-';
};

const ArrowIcon = posed.div({
  up: {
    rotate: '180deg',
  },
  down: {
    rotate: '0deg',
  },
});

function OrderItem({ orderItem, lastItem, isItemColored, usesNewStructure }) {
  OrderItem.propTypes = {
    orderItem: shape({}),
    lastItem: bool,
    isItemColored: bool,
    usesNewStructure: bool,
  };

  OrderItem.defaultProps = {
    orderItem: {},
    lastItem: false,
    isItemColored: false,
    usesNewStructure: false,
  };

  const [showPersonalizations, setShowPersonalizations] = useState([]);
  const [showOrder, setShowOrder] = useState([]);

  const showDate = dateString => {
    if (dateString.length > 9) {
      return dateString.slice(0, 10);
    }
    return null;
  };

  const toggleView = item => {
    if (showPersonalizations.includes(item.id)) {
      const updatedArr = showPersonalizations.filter(pers => pers !== item.id);
      setShowPersonalizations(updatedArr);
    } else {
      const newArr = [...showPersonalizations, item.id];
      setShowPersonalizations(newArr);
    }
  };

  const toggleViewOrder = item => {
    if (showOrder.includes(item.id)) {
      const updatedArr = showOrder.filter(pers => pers !== item.id);
      setShowOrder(updatedArr);
    } else {
      const newArr = [...showOrder, item.id];
      setShowOrder(newArr);
    }
  };

  const checkIfShowMore = Id => {
    const foundValue = showPersonalizations.find(item => item === Id);

    if (foundValue) {
      return true;
    }
    return false;
  };

  const checkIfShowOrder = Id => {
    const foundValue = showOrder.find(item => item === Id);
    if (foundValue) {
      return true;
    }
    return false;
  };

  const findPersonalizationStyle = isColored => {
    let styleToUse = 'personalization-item-old';
    if (isColored) {
      styleToUse += ' table-dark-line';
    }
    return styleToUse;
  };

  const findPersonalizationStyleMobile = isColored => {
    let styleToUse = 'personalization-item-old-mobile';
    if (isColored) {
      styleToUse += ' table-dark-line';
    }
    return styleToUse;
  };

  const showMoreInformation = (basketItems, orderId) => {
    if (basketItems && basketItems.length > 0) {
      return basketItems.map((item, parentIndex) => {
        const isColored = parentIndex % 2 === 0;
        const itemIsLast = basketItems.length === parentIndex + 1;
        return (
          <div key={orderId + item.id}>
            <div className={findPersonalizationStyle(isColored, itemIsLast)}>
              <div className="order-personalization-line05flex">
                <button
                  type="button"
                  onClick={() => toggleView(item)}
                  className="toggle-personalizations-button"
                >
                  <ArrowIcon
                    className="expand-icon-orders"
                    pose={checkIfShowMore(item.id) ? 'up' : 'down'}
                  >
                    <ChevronDownIcon />
                  </ArrowIcon>
                </button>
              </div>
              <div className="order-personalization-line">{item.name}</div>
              <div className="order-personalization-line">{item.size}</div>
              <div className="order-personalization-line">{item.ean}</div>
              <div className="order-personalization-line">{item.sku}</div>
              <div className="order-personalization-line-quantity">
                {item.count}
              </div>
            </div>
            <div
              className={findPersonalizationStyleMobile(isColored, itemIsLast)}
            >
              <div className="order-personalization-line05flex">
                <button
                  type="button"
                  onClick={() => toggleView(item)}
                  className="toggle-personalizations-button"
                >
                  <ArrowIcon
                    className="expand-icon-orders"
                    pose={checkIfShowMore(item.id) ? 'up' : 'down'}
                  >
                    <ChevronDownIcon />
                  </ArrowIcon>
                </button>
              </div>
              <div>
                <div className="order-personalization-line-old-mobile">
                  <div className="line-old-title">{'Produkt: '}</div>
                  <div className="line-old-value">{item.name}</div>
                </div>
                <div className="order-personalization-line-old-mobile">
                  <div className="line-old-title">{'Str.: '}</div>
                  <div className="line-old-value">{item.size}</div>
                </div>
                <div className="order-personalization-line-old-mobile">
                  <div className="line-old-title">{'EAN: '}</div>
                  <div className="line-old-value">{item.ean}</div>
                </div>
                <div className="order-personalization-line-old-mobile">
                  <div className="line-old-title">{'SKU: '}</div>
                  <div className="line-old-value">{item.sku}</div>
                </div>
                <div className="order-personalization-line-old-mobile">
                  <div className="line-old-title">{'Antall: '}</div>
                  <div className="line-old-value">{item.count}</div>
                </div>
              </div>
            </div>
            <div className="personliaziation-line-container">
              <div className="flex-placeholder-personalization-line" />
              <div
                className={
                  checkIfShowMore(item.id)
                    ? 'show-personalizations'
                    : 'hide-personalizations'
                }
              >
                <PersonalizationItem
                  personalizationItems={item.personalizations}
                  orderId={orderItem?.id}
                  orderItem={orderItem}
                />
              </div>
            </div>
          </div>
        );
      });
    }
    return null;
  };

  const findOrderItemStyle = () => {
    let styleToUse = 'order-item';

    if (lastItem) {
      styleToUse += ' order-item-no-border';
    }

    if (isItemColored) {
      styleToUse += ' order-item-colored-background';
    }
    return styleToUse;
  };

  const findStylingOfItem = () => {
    let useStyle = 'personalization-item';

    if (lastItem) {
      useStyle += ' personalization-item-last';
    }
    return useStyle;
  };

  const findStylingOfItemMobile = () => {
    let useStyle = 'personalization-item-mobile';

    if (lastItem) {
      useStyle += ' personalization-item-last-mobile';
    }
    return useStyle;
  };

  const renderOldPersonalizations = () => (
    <div className="show-more-order-item">
      <div className="personalizations-container-order">
        <div className="personalizations-title-container">
          <div className="personalizations-item-title05flex" />
          <div className="personalizations-item-title">Produkt</div>
          <div className="personalizations-item-title">Størrelse</div>
          <div className="personalizations-item-title-numbers">Ean</div>
          <div className="personalizations-item-title-numbers">SKU</div>
          <div className="order-personalization-line-quantity">Antall</div>
        </div>
        <div>{showMoreInformation(orderItem.basket_items, orderItem.id)}</div>
      </div>
    </div>
  );

  const remapPersonalizationsIntoArr = () => {
    const remapBasketItemsIntoArr = orderItem?.basket_items?.reduce(
      (acc, currItem) =>
        acc.concat([
          {
            ...currItem,
          },
        ]),
      [],
    );

    const remapPersIntoArr = remapBasketItemsIntoArr.reduce(
      // reduce all personalizations into one array
      (acc, currItem) => {
        // if no personalizations - add item to arr as well
        if (currItem?.personalizations?.length === 0) {
          return acc.concat({
            parentId: currItem?.id,
            parentEan: currItem?.ean,
            parentSku: currItem?.sku,
            parentName: currItem?.name,
            parentSize: currItem?.size,
            quantity: currItem?.count,
          });
        }
        // TO DO - handle case where not
        // every item has been personalized
        // currItem?.personalizations - count "quantity" in each element of arr
        const totalCountOfPers = currItem?.personalizations.reduce(
          (accCount, currCount) => {
            if (
              typeof currCount?.quantity === 'number' &&
              typeof accCount === 'number'
            ) {
              return accCount + currCount.quantity;
            }
            return accCount;
          },
          0,
        );

        if (currItem.count > totalCountOfPers) {
          const missingNumber = currItem.count - totalCountOfPers;
          acc.push({
            parentId: currItem?.id,
            parentEan: currItem?.ean,
            parentSku: currItem?.sku,
            parentName: currItem?.name,
            parentSize: currItem?.size,
            quantity: missingNumber,
          });
        }

        return acc.concat(
          currItem?.personalizations.map(
            // add parent parameters for reduced array elements
            pers => ({
              ...pers,
              parentId: currItem?.id,
              parentEan: currItem?.ean,
              parentSku: currItem?.sku,
              parentName: currItem?.name,
              parentSize: currItem?.size,
            }),
          ),
        );
      },
      [],
    );
    return remapPersIntoArr;
  };

  const renderNewPersonalizationItems = () => {
    const remapPersIntoArr = remapPersonalizationsIntoArr();
    if (remapPersIntoArr?.length === 0) {
      return null;
    }
    return remapPersIntoArr.map((pers, index) => {
      const isPersLast = remapPersIntoArr.length === index + 1;
      const isColored = index % 2 === 0;

      const findLineStyle = () => {
        let styleToUse = 'new-personalization-line-container';
        if (isPersLast) {
          styleToUse += ' personalization-no-bottom-border';
        }

        if (isColored) {
          styleToUse += ' table-dark-line';
        }
        return styleToUse;
      };
      return (
        <div id={pers?.id}>
          <div className="new-pers-placeholder" />
          <div className={findLineStyle()}>
            <div className="personalization-item-new">
              {pers?.parentName ? pers.parentName : '-'}
            </div>
            <div className="personalization-item-new">
              {pers?.parentSize ? pers.parentSize : '-'}
            </div>
            <div className="personalization-item-new">
              {pers?.parentEan ? pers.parentEan : '-'}
            </div>
            <div className="personalization-item-new">
              {pers?.parentSku ? pers.parentSku : '-'}
            </div>
            <div className="personalization-item-new-small">
              {pers?.quantity ? pers.quantity : '-'}
            </div>
            <div className="personalization-item-new-small">
              {findPersonalizationItemValue(pers?.items, 'Navn')}
            </div>
            <div className="personalization-item-new-small">
              {findPersonalizationItemValue(pers?.items, 'Initialer')}
            </div>
            <div className="personalization-item-new-small">
              {findPersonalizationNumberValue(pers?.items)}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderNewPersonalizationItemsMobile = () => {
    const remapPersIntoArr = remapPersonalizationsIntoArr();

    if (remapPersIntoArr?.length === 0) {
      return null;
    }
    return remapPersIntoArr.map((pers, index) => {
      const isPersLast = remapPersIntoArr.length === index + 1;
      const isColored = index % 2 === 0;

      const findLineStyle = () => {
        let styleToUse = 'new-personalization-line-container-mobile';
        if (isPersLast) {
          styleToUse += ' personalization-no-bottom-border';
        }

        if (isColored) {
          styleToUse += ' table-dark-line';
        }
        return styleToUse;
      };
      return (
        <div id={pers?.id} className="new-pers-list-wrapper">
          <div className="new-pers-placeholder" />
          <div className={findLineStyle()}>
            <div className="personalization-item-new">
              <div className="new-pers-vertical-cat-title-text">Produkt:</div>
              <div className="new-pers-vertical-cat-name-text">
                {pers?.parentName ? pers.parentName : '-'}
              </div>
            </div>
            <div className="personalization-item-new">
              <div className="new-pers-vertical-cat-title-text">Str:</div>
              <div className="new-pers-vertical-cat-name-text">
                {pers?.parentSize ? pers.parentSize : '-'}
              </div>
            </div>
            <div className="personalization-item-new">
              <div className="new-pers-vertical-cat-title-text">EAN:</div>
              <div className="new-pers-vertical-cat-name-text">
                {pers?.parentEan ? pers.parentEan : '-'}
              </div>
            </div>
            <div className="personalization-item-new">
              <div className="new-pers-vertical-cat-title-text">SKU:</div>
              <div className="new-pers-vertical-cat-name-text">
                {pers?.parentSku ? pers.parentSku : '-'}
              </div>
            </div>
            <div className="personalization-item-new-small">
              <div className="new-pers-vertical-cat-title-text">Antall:</div>
              <div className="new-pers-vertical-cat-name-text">
                {pers?.quantity ? pers.quantity : '-'}
              </div>
            </div>
            <div className="personalization-item-new-small">
              <div className="new-pers-vertical-cat-title-text">Navn:</div>
              <div className="new-pers-vertical-cat-name-text">
                {findPersonalizationItemValue(pers?.items, 'Navn')}
              </div>
            </div>
            <div className="personalization-item-new-small">
              <div className="new-pers-vertical-cat-title-text">Initialer:</div>
              <div className="new-pers-vertical-cat-name-text">
                {findPersonalizationItemValue(pers?.items, 'Initialer')}
              </div>
            </div>
            <div className="personalization-item-new-small">
              <div className="new-pers-vertical-cat-title-text">Nummer:</div>
              <div className="new-pers-vertical-cat-name-text">
                {findPersonalizationNumberValue(pers?.items)}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderNewPersonalizations = () => (
    <div className="new-pers-mode-container">
      <div className="new-personalization-desktop">
        <div className="new-personalization-line-container new-per-title-bold">
          <div className="personalization-item-new">Produkt</div>
          <div className="personalization-item-new">Str</div>
          <div className="personalization-item-new">EAN</div>
          <div className="personalization-item-new">SKU</div>
          <div className="personalization-item-new-small">Antall</div>
          <div className="personalization-item-new-small">Navn</div>
          <div className="personalization-item-new-small">Initialer</div>
          <div className="personalization-item-new-small">Nummer</div>
        </div>
        {renderNewPersonalizationItems()}
      </div>
      <div className="new-personalization-mobile">
        {renderNewPersonalizationItemsMobile()}
      </div>
    </div>
  );

  const renderPersonalizations = () => {
    if (checkIfShowOrder(orderItem.id)) {
      if (usesNewStructure) {
        return renderNewPersonalizations();
      }
      return renderOldPersonalizations();
    }
    return null;
  };

  return (
    <div className={findOrderItemStyle()}>
      <div className={findStylingOfItem()}>
        <div className="order-personalization-line-flex05">
          <button
            onClick={() => toggleViewOrder(orderItem)}
            className="expand-order-container"
            type="button"
          >
            <ArrowIcon
              className="expand-icon-orders"
              pose={checkIfShowOrder(orderItem.id) ? 'up' : 'down'}
            >
              <ChevronDownIcon />
            </ArrowIcon>
          </button>
        </div>
        <div className="order-personalization-line">{`${orderItem.id}`}</div>
        <div className="order-personalization-line">
          {`${showDate(orderItem.created_at)}`}
        </div>
        <div className="order-personalization-line">
          {`${orderItem.status}`}
        </div>
        <div className="order-personalization-line">
          {`${countQuantityPerOrderItem(orderItem)}`}
        </div>
      </div>
      <div className={findStylingOfItemMobile()}>
        <div className="order-personalization-line-flex05">
          <button
            onClick={() => toggleViewOrder(orderItem)}
            className="expand-order-container"
            type="button"
          >
            <ArrowIcon
              className="expand-icon-orders"
              pose={checkIfShowOrder(orderItem.id) ? 'up' : 'down'}
            >
              <ChevronDownIcon />
            </ArrowIcon>
          </button>
        </div>
        <div className="order-personalization-line-mobile">
          <div className="order-line-mobile">
            <div className="new-pers-vertical-cat-title-text">ID:</div>
            <div className="new-pers-vertical-cat-name-text">
              {`${orderItem.id}`}
            </div>
          </div>
          <div className="order-line-mobile">
            <div className="new-pers-vertical-cat-title-text">Ordredato:</div>
            <div className="new-pers-vertical-cat-name-text">
              {`${showDate(orderItem.created_at)}`}
            </div>
          </div>
          <div className="order-line-mobile">
            <div className="new-pers-vertical-cat-title-text">Status:</div>
            <div className="new-pers-vertical-cat-name-text">
              {`${orderItem.status}`}
            </div>
          </div>
          <div className="order-line-mobile">
            <div className="new-pers-vertical-cat-title-text">
              Antall varer:
            </div>
            <div className="new-pers-vertical-cat-name-text">
              {`${countQuantityPerOrderItem(orderItem)}`}
            </div>
          </div>
        </div>
      </div>
      <div className="" />
      <div className="new-pers-line-wrapper">
        <div className="pers-placeholder" />
        <div className="pers-content-container">{renderPersonalizations()}</div>
      </div>
    </div>
  );
}

export default OrderItem;
