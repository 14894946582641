/* eslint-disable camelcase */
import React, { Component } from 'react';
import './styles.scss';
import { TextField, Button } from '@mui/material';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { shape, func } from 'prop-types';
import { updateMyAddress } from '../../util/Api';
import { updateUser } from '../../actions/userAction';

const propTypes = {
  setLoading: func.isRequired,
  dispatch: func.isRequired,
  userProfile: shape({}).isRequired,
};

const defaultProps = {};

class Profile extends Component {
  constructor(props) {
    super(props);
    const { userProfile } = this.props;
    this.state = {
      first_name:
        userProfile && userProfile.firstname ? userProfile.firstname : '',
      last_name:
        userProfile && userProfile.lastname ? userProfile.lastname : '',
      address:
        userProfile && userProfile.streetname ? userProfile.streetname : '',
      phone_number:
        userProfile && userProfile.username ? userProfile.username : '',
      email: userProfile && userProfile.email ? userProfile.email : '',
      postal_code:
        userProfile && userProfile.postcode ? userProfile.postcode : '',
    };
  }

  updateUserData = async () => {
    const { setLoading, selectedClub } = this.props;
    setLoading(true);
    const { first_name, last_name, address, email, postal_code } = this.state;

    const { userProfile, dispatch } = this.props;
    const updatedObject = {
      first_name,
      last_name,
      address,
      email,
      postcode: postal_code,
    };
    try {
      await updateMyAddress(updatedObject, selectedClub.id);

      const updatedUser = {
        ...userProfile,
        first_name,
        last_name,
        email,
        postal_code,
      };
      dispatch(updateUser(updatedUser));

      toast.success('Profilen ble oppdatert!');
      setLoading(false);
    } catch (err) {
      toast.error('Klarte ikke å oppdatere profilen...');
      setLoading(false);
    }
  };

  render() {
    const { first_name, last_name, address, phone_number, email, postal_code } =
      this.state;

    const handlePostcodeChange = e => {
      if (e?.target?.value?.length <= 4) {
        this.setState({ postal_code: e.target.value });
      }
    };
    return (
      <div className="main-wrap">
        <div className="profile-page-title">Personlig data</div>
        <div className="profile-page-wrapper">
          <div className="constact-info-text-block-wrap">
            <div className="constact-info-block">
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={first_name}
                onChange={e => this.setState({ first_name: e.target.value })}
                label="Fornavn*"
                className="my-info-input"
                variant="standard"
              />
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={last_name}
                onChange={e => this.setState({ last_name: e.target.value })}
                label="Etternavn*"
                className="my-info-input"
                variant="standard"
              />
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={address}
                onChange={e => this.setState({ address: e.target.value })}
                label="Adresse*"
                className="my-info-input"
                variant="standard"
              />
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={postal_code}
                onChange={e => handlePostcodeChange(e)}
                label="Postkode*"
                className="my-info-input"
                variant="standard"
              />
            </div>
            <div className="constact-info-block">
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={phone_number}
                disabled
                label="Telefonnummer"
                className="my-info-input"
                variant="standard"
              />
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={email}
                onChange={e => this.setState({ email: e.target.value })}
                label="E-post adresse*"
                className="my-info-input"
                variant="standard"
              />
            </div>
          </div>
          <div className="myPage-button-container">
            <div className="profile-button">
              <Button
                variant="contained"
                size="large"
                color="primary"
                className="my-info-buttom-submit"
                onClick={() => this.updateUserData()}
              >
                OPPDATER
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

const mapStateToProps = state => ({
  cartItems: state.shoppingCartReducer.cartItems,
  userProfile: state.userReducer.userProfile,
  selectedClub: state.clubsReducer.selectedClub,
});

export default connect(mapStateToProps)(Profile);
