import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute({ children }) {
  const RenderComponent = children;
  const isAuthenticated = useSelector(
    state => state.userReducer.isAuthenticated,
  );

  if (!isAuthenticated) {
    return <Navigate to="/log_in" replace />;
  }

  return RenderComponent;
}

export default PrivateRoute;
