import axios from 'axios';

/* const API_BASE_URL = '//sales-api-dot-vinnvinn-prod.ew.r.appspot.com/api/sales' */
// const local_api = '//klubvareportal.blue/api';
const API_BASE_URL =
  process.env.REACT_APP_ENV === 'production'
    ? '//klubbvare-api-dot-vinnvinn-prod.ew.r.appspot.com/api'
    : '//klubbvare-api-dot-vinnvinn-test.ew.r.appspot.com/api';
/* const API_BASE_URL =
  process.env.REACT_APP_ENV === 'production'
    ? '//klubbvare-api-dot-vinnvinn-prod.ew.r.appspot.com/api'
    : 'http://kvp.api:8888/api'; */

const API = axios.create({
  baseURL: API_BASE_URL,
  responseType: 'json',
});

const authtoken = localStorage.getItem('authToken');
API.defaults.headers.common.Authorization = authtoken
  ? `Bearer ${authtoken}`
  : '';

const getOneTimePassword = phoneNr =>
  API.post('/request_otp', {
    phone_number: phoneNr,
  });

const loginWithSmsToken = (phoneNr, token) =>
  API.post('/login', {
    phone_number: phoneNr,
    token,
  });

const checkAuthToken = clubId => API.get(`/check_authtoken?club_id=${clubId}`);

const getProductsPaged = (page, clubId) =>
  API.get(`/products?page=${page}&club_id=${clubId}&page_size=${20}`);

const sendOrder = (orderArr, deliveryInfo, billingInfo, clubId) =>
  API.post('/submit_order', {
    order_items: orderArr,
    delivery_info: deliveryInfo,
    billing_info: billingInfo,
    club_id: clubId,
  });

const updateMyAddress = (addressObj, clubId) =>
  API.put(`/me?club_id=${clubId}`, addressObj);

const getMyOrders = clubId => API.get(`/order_history?club_id=${clubId}`);

const getClubStats = clubId => API.get(`/club_stats?club_id=${clubId}`);

export {
  API,
  getOneTimePassword,
  loginWithSmsToken,
  checkAuthToken,
  getProductsPaged,
  sendOrder,
  updateMyAddress,
  getMyOrders,
  getClubStats,
};
