import React from 'react';
import { shape, func } from 'prop-types';
import './styles.scss';
import { TextField } from '@mui/material';

function ContactInfomastionForm({
  deliveryValues,
  changeFormValue,
  invoiceValues,
  invoiceErrors,
  deliveryErrors,
}) {
  const findHelperText = (type, key) => {
    if (type === 'delivery') {
      const foundValue = deliveryErrors.find(
        item => item.key === key && item.errorText !== '',
      );
      if (foundValue) {
        return foundValue.errorText;
      }
      return null;
    }
    if (type === 'invoice') {
      const foundValue = invoiceErrors.find(
        item => item.key === key && item.errorText !== '',
      );
      if (foundValue) {
        return foundValue.errorText;
      }
      return null;
    }
    return false;
  };

  return (
    <div className="contact-info-container">
      <div className="contact-info-section-container">
        <div className="contact-info-section-left-edit">
          <div className="contact-info-section-title">LEVERINGSADRESSE</div>
          <div className="constact-info-text-block">
            <div className="constact-info-text-block">
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={deliveryValues.first_name}
                onChange={e =>
                  changeFormValue('delivery', 'first_name', e.target.value)
                }
                label="Fornavn"
                className="contact-info-input"
                required
                error={findHelperText('delivery', 'first_name')}
                helperText={findHelperText('delivery', 'first_name')}
                variant="standard"
              />
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={deliveryValues.last_name}
                onChange={e =>
                  changeFormValue('delivery', 'last_name', e.target.value)
                }
                label="Etternavn"
                className="contact-info-input"
                required
                error={findHelperText('delivery', 'last_name')}
                helperText={findHelperText('delivery', 'last_name')}
                variant="standard"
              />
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={deliveryValues.address}
                onChange={e =>
                  changeFormValue('delivery', 'address', e.target.value)
                }
                label="Adresse"
                className="contact-info-input"
                required
                error={findHelperText('delivery', 'address')}
                helperText={findHelperText('delivery', 'address')}
                variant="standard"
              />
            </div>
            <TextField
              type="number"
              InputLabelProps={{ shrink: true }}
              value={deliveryValues.postal_code}
              onChange={e =>
                changeFormValue('delivery', 'postal_code', e.target.value)
              }
              label="Postnummer"
              className="contact-info-input"
              required
              error={findHelperText('delivery', 'postal_code')}
              helperText={findHelperText('delivery', 'postal_code')}
              variant="standard"
            />
            <div className="constact-info-text-block">
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={deliveryValues.team}
                onChange={e =>
                  changeFormValue('delivery', 'team', e.target.value)
                }
                label="Lag"
                className="contact-info-input"
                required
                error={findHelperText('delivery', 'team')}
                helperText={findHelperText('delivery', 'team')}
                variant="standard"
              />
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={deliveryValues.phone_number}
                onChange={e =>
                  changeFormValue('delivery', 'phone_number', e.target.value)
                }
                label="Telefonnummer"
                className="contact-info-input"
                required
                error={findHelperText('delivery', 'phone_number')}
                helperText={findHelperText('delivery', 'phone_number')}
                variant="standard"
              />
              <TextField
                type="email"
                InputLabelProps={{ shrink: true }}
                value={deliveryValues.email}
                onChange={e =>
                  changeFormValue('delivery', 'email', e.target.value)
                }
                label="E-post adresse"
                className="contact-info-input"
                required
                error={findHelperText('delivery', 'email')}
                helperText={findHelperText('delivery', 'email')}
                variant="standard"
              />
            </div>
          </div>
        </div>
        <div className="contact-info-section-right-edit">
          <div className="contact-info-section-title">FAKTURAADRESSE</div>
          <div className="constact-info-text-block">
            <div className="constact-info-text-block">
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={invoiceValues.first_name}
                onChange={e =>
                  changeFormValue('invoice', 'first_name', e.target.value)
                }
                label="Fornavn"
                className="contact-info-input"
                required
                error={findHelperText('invoice', 'first_name')}
                helperText={findHelperText('invoice', 'first_name')}
                variant="standard"
              />
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={invoiceValues.last_name}
                onChange={e =>
                  changeFormValue('invoice', 'last_name', e.target.value)
                }
                label="Etternavn"
                className="contact-info-input"
                required
                error={findHelperText('invoice', 'last_name')}
                helperText={findHelperText('invoice', 'last_name')}
                variant="standard"
              />
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                value={invoiceValues.address}
                onChange={e =>
                  changeFormValue('invoice', 'address', e.target.value)
                }
                label="Adresse"
                className="contact-info-input"
                required
                error={findHelperText('invoice', 'address')}
                helperText={findHelperText('invoice', 'address')}
                variant="standard"
              />
              <TextField
                type="number"
                InputLabelProps={{ shrink: true }}
                value={invoiceValues.postal_code}
                onChange={e =>
                  changeFormValue('invoice', 'postal_code', e.target.value)
                }
                label="Postnummer"
                className="contact-info-input"
                required
                error={findHelperText('invoice', 'postal_code')}
                helperText={findHelperText('invoice', 'postal_code')}
                variant="standard"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ContactInfomastionForm.propTypes = {
  changeFormValue: func.isRequired,
  deliveryValues: shape({}),
  invoiceValues: shape({}),
};

ContactInfomastionForm.defaultProps = {
  deliveryValues: {},
  invoiceValues: {},
};

export default ContactInfomastionForm;
