/* eslint-disable no-plusplus */
import React from 'react';
import { shape, arrayOf, func } from 'prop-types';
import './styles.scss';

function AvailableSizes({ cart, setCart, text, sizes }) {
  AvailableSizes.propTypes = {
    cart: arrayOf(shape({})),
    setCart: func.isRequired,
    text: shape({}),
    sizes: arrayOf(shape({})),
  };

  AvailableSizes.defaultProps = {
    cart: [],
    text: {},
    sizes: [],
  };

  const personalizationsDummyArr = ['Navn', 'Initialer', 'Nummer'];
  const personalizationsDummyArrCollect = [
    'Navn',
    'Initialer',
    'Nummer fra',
    'Nummer til',
  ];

  const addNewItemToCart = (size, value) => {
    const cartCopy = cart;

    const saleObj = {};
    saleObj.id = Math.random();
    saleObj.quantity = value;
    saleObj.size = size;
    saleObj.text = text;
    saleObj.availablePersonalizations = personalizationsDummyArr;
    saleObj.availablePersonalizationsForSerializing =
      personalizationsDummyArrCollect;
    saleObj.activePersonalizations = [];
    saleObj.usesCollectedStructure = true;

    if (cartCopy.length > 0) {
      cartCopy.push(saleObj);
      setCart(cartCopy);
    }
    if (cartCopy.length === 0) {
      setCart([saleObj]);
    }
  };

  const justCheckIfInCart = size => {
    let isInCart = false;
    if (cart && cart.length > 0) {
      cart.forEach(item => {
        const parsedQuantity = parseInt(item.quantity, 10);
        if (
          item.size.sku === size.sku &&
          typeof parsedQuantity === 'number' &&
          parsedQuantity > 0
        ) {
          isInCart = true;
        }
      });
    }
    return isInCart;
  };

  const handleQuantityChange = (size, value) => {
    let checkedIfIncart = false;
    const cartCopy = cart;

    cartCopy.forEach(() => {
      if (justCheckIfInCart(size)) {
        const newCart = cartCopy.filter(item => item.size.sku !== size.sku);

        if (value > 0) {
          const saleObj = {};
          saleObj.id = Math.random();
          saleObj.quantity = value;
          saleObj.size = size;
          saleObj.text = text;
          saleObj.availablePersonalizations = personalizationsDummyArr;
          saleObj.availablePersonalizationsForSerializing =
            personalizationsDummyArrCollect;
          saleObj.activePersonalizations = [];
          newCart.push(saleObj);
        }
        setCart(newCart);

        checkedIfIncart = true;
      }
    });
    return checkedIfIncart;
  };

  const handleInput = (value, size) => {
    const intNumber = parseInt(value, 10);
    if (typeof intNumber === 'number' && intNumber > 0) {
      if (cart.length === 0) {
        addNewItemToCart(size, value);
        return;
      }
      if (justCheckIfInCart(size) === false) {
        addNewItemToCart(size, value);
      }
      handleQuantityChange(size, value);
    } else {
      // remove from cart
      handleQuantityChange(size, value);
    }
  };

  const findValue = size => {
    const value = cart.find(item => item.size.sku === size.sku);
    const returnValue = value ? value.quantity : '';

    return returnValue;
  };

  if (sizes) {
    return sizes.map(size => {
      const isInCart = justCheckIfInCart(size);

      return (
        <div className="sizeAndAmountContainerInCard" key={size.sku}>
          <span
            key={size.size}
            className={isInCart ? 'sizeCardInCardActive' : 'sizeCardInCard'}
          >
            {size.size}
          </span>
          <input
            type="number"
            className={
              isInCart ? 'amountPerSizeInCardActive' : 'amountPerSizeInCard'
            }
            placeholder="0"
            onChange={e => handleInput(e.target.value, size)}
            value={findValue(size)}
            min={0}
          />
        </div>
      );
    });
  }
  return null;
}

export default AvailableSizes;
